import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import axios from "axios";
import * as FlexmonsterReact from "react-flexmonster";
import "flexmonster/flexmonster.css";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import toJsonSchema from "to-json-schema";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useLocation, useNavigate } from "react-router";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { IconButton, styled } from "@mui/material";
import ArangoDBAPI from "./ArangoDBAPI";
import { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  getDataMappingFilter,
  getMetaSamepleJson,
  getRepoid,
  UpdateRepo,
  upsertRepo,
} from "./repoApiCalls";
import FilterListIcon from "@material-ui/icons/FilterList";
import {
  FormControl,
  FormControlLabel,
  InputBase,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Switch,
  Typography,
  Button,
  Divider,
  TextareaAutosize,
} from "@material-ui/core";
import { AlertContext } from "./context/alert.context";
import { IsRequired } from "./components/Required";
import CustionAddText from "./components/customAddTextBox";
import { withStyles } from "@material-ui/core/styles";
import config from "./config";

const useSytle = makeStyles((theme) => ({
  toggle: {
    "& .MuiToggleButtonGroup-grouped": {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      border: "1px solid #e0e0e0",
      fontSize: 12,
      minWidth: 60,

      "&:not(:first-of-type)": {
        borderRadius: theme.shape.borderRadius,
      },
      "&:first-of-type": {
        borderRadius: theme.shape.borderRadius,
      },
    },
    "& .Mui-selected": {
      backgroundColor: `${theme.palette.info.main} !important`,
      borderColor: `${theme.palette.info.main} !important`,
      color: "#fff !important",
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),

      "& .MuiToggleButton-label": {
        color: "#fff !important",
      },
    },
  },
  paper: {
    borderRadius: 12,
    marginTop: 8,
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,

    "& li": {
      // fontWeight: 200,
      paddingTop: 8,
      paddingBottom: 8,
      fontSize: "1rem",
      fontFamily: "TDS_Regular",
    },
    "& li:hover": {
      color: theme.palette.common.white,
      background: theme.palette.info.main,
    },
    "& li.Mui-selected": {
      color: theme.palette.common.black,
      background: theme.palette.info.light,
    },
    "& li.Mui-selected:hover": {
      // background: "#6EC177",
      color: theme.palette.common.white,
      background: theme.palette.info.main,
    },
  },
  paper: {
  width: "100%",
  margin: "auto",
  height: "100%",
  padding: " 0 12px",
  overflow: "auto",
},
  grid1: {
  height: "calc(100% - 175px)",
  overflowY: "auto",
  padding: "8px",
},
  div1: {
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  flexDirection: "column",
  // width: "300px",
  margin: "auto",
},
  div2: {
  display: "flex",
  flexWrap: "wrap",
  boxSizing: "border-box",
  height: "calc(100% - 175px)",
},
  iframe: {
  height: "100%",
  width: "100%",
  padding: "20px"
},
  div3: {
  display: "flex",
  flexDirection: "row",
  margin: "auto",
},
  div4: {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
},
  div5: {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "0 30px",
},
  div6: {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
},
  grid2: {
  display: "flex",
  flexDirection: "column",
  // alignItems: "center",
  justifyContent: "center",
},
  typo: {
  textTransform: "capitalize",
  fontWeight: "600",
},
  div7: {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
},
  box: {
  display: "flex",
  justifyContent: "flex-end",
  alignContent: "flex-end",
},
  div8: {
  display: "flex",
  flexDirection: "row",
  padding: "10px"
},
  div9: {
  display: "flex",
  flexDirection: "row",
  padding: "10px"
},
  autosize: {
  width: "100%",
  borderColor: "#b3b3b3",
  marginTop: "8px",
  borderRadius: "5px",
  padding: "8.5px 14px",
  resize: "vertical",
}}
));

const BootstrapInput1 = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #e0e0e0",
    fontSize: "1rem",
    fontFamily: "TDS_Regular",
    padding: "10px 26px 10px 12px",
    
  },
}));

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 14,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    
    "&:focus": {
      borderRadius: 4,
      
    },
  },
}))(InputBase);

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const Input = styled("input")({
  display: "none",
});


function Stepform() {
  const classes = useSytle();
  const menuProps = {
    classes: {
      list: classes.list,
      paper: classes.paper,
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
    getContentAnchorEl: null,
  };
  const navigation = useNavigate();
  const location = useLocation();
  const [steps, setSteps] = React.useState([
    "Report-Details",
    "Configuration",
    "Final",
  ]);
  const [pdfView, setPdfView] = React.useState(false);
  const [finalList, setFinallist] = React.useState({});
  const [reportIdForJasper, setReportIdForJasper] = React.useState(
    location?.state.reportPdfId
  );
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [schema, setSchema] = React.useState(location?.state.readjsonschema);
  const pivot = React.useRef(null);
  const [alertmsg, setAlertmsg] = React.useState("");
  const [template, setTemplate] = React.useState({
    reportId: String(location?.state.reportid),
    reportName: String(location?.state.reportname),
    reportType: String(location?.state.reporttype),
    rolecaccess: String(location?.state.rolecaccess),
    reportcacheduration: String(location?.state.reportcacheduration),
    fileurl: String(location?.state.fileurl),
    parentreport: String(location?.state.parentreport ?? ""),
    reportGrpObj: location?.state,
    url: location?.state?.url,
  });
  const [runRegister, setRunRegister] = React.useState(false);
  const alertMessage = React.useContext(AlertContext);
  const [column, setColumn] = React.useState("");
  const [columnvalue, setColumnvalues] = React.useState("");
  const [alertstatus, setAlertStatus] = React.useState("");
  const [readquery, setReadquery] = React.useState(location?.state.readquery);
  const [open, setOpen] = React.useState(false);
  const [opendia, setOpendia] = React.useState(false);
  const [headername, setHeadername] = React.useState("");
  const [logourl, setLogourl] = React.useState("");
  const [diaforhead, setDiaforhead] = React.useState(false);
  const [dateflex, setDateflex] = React.useState("");
  const [pageflex, setPageflex] = React.useState("");
  const [fmheadfoot, setFmheadfoot] = React.useState(location.state.fmheadfoot);
  const [footercont, setFootercont] = React.useState();
  const [filedata, setFiledata] = React.useState({
    name: location?.state.jrxmlfilename,
    filedata: location?.state.jrxmlfiledata,
  });
  const [jrxmltemplate, setJrmltemplate] = React.useState(
    JSON.stringify(location.state.jrxmltemplate)
  );
  const [readyQueryJasper, setReadQueryJasper] = React.useState(
    location?.state.readquery
  );
  const [fileid, setFileid] = React.useState("");
  const [fmrender, setFmender] = React.useState([]);
  const [inputParams, setinputParams] = React.useState("");
  const [reportgroupType, setreportgroupType] = React.useState([]);
  const [reportTypeArr, setreportTypeArr] = React.useState([]);
  // const [valueType, setValueType] = React.useState("");
  // const [columnType, setColumnType] = React.useState("");
  const [regreportid, setregreportid] = React.useState("");
  const [jasperdownloadurl, setjasperdownloadurl] = React.useState({});
  console.log("location", location.state);
  const [listParams, setListParams] = React.useState(
    location?.state.inputparams
  );
  console.log("location", location.state);
  const [pdf, setPdf] = React.useState("");
  const [QueryFilter, setQueryFilter] = React.useState([]);
  const [QueryObj, setQueryobj] = React.useState({});
  const [sampleMetaJson, setsampleMetaJson] = React.useState({});
  const [isRequiredJson, setisRequiredJson] = React.useState({});
  const [MetaJson, setMetaJson] = React.useState({});
  const [openFilter, setCloseFilter] = React.useState(false);
  const [isAnd, setIsAnd] = React.useState(false);
  const [isOr, setIsOr] = React.useState(false);
  const [isFourthStep, setFourthStep] = React.useState(false);
  const [arraysType, setArraysType] = React.useState({});
  const [isMultipleEntities, setIsMultipleEntities] = React.useState(false);
  const [jasperInputParams, setJasperInputParams] = React.useState([]);
  // const [mappingSchema, setMappingSchema] = React.useState(false);
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const [openApi, setOpenApi] = React.useState({
    open: false,
  });

  const [mappingFilter, setMappingFilter] = React.useState({});
  const [types, setTypes] = React.useState({});

  const onHandleAPiChange = (key, value) => {
    setMappingFilter({ ...mappingFilter, [key]: value });
  };

  const FromApiClicked = () => {
    setOpenApi({ ...openApi, open: true });
  };

  const closeApiModel = () => {
    setOpenApi({ ...openApi, open: false });
  };
  // const [mappingSchema, setMappingSchema] = React.useState(false);

  const onTriggerClicked = async () => {
    debugger;
    let valid = isValid(mappingFilter);
    if (valid) {
      let _ = await getDataMappingFilter(
        mappingFilter?.url,
        mappingFilter?.query
      );
      if (_.Code === 201) {
        setTypes(_.result);
        console.log(_.result);
      } else {
        alertMessage.setAlert({
          ...alertMessage,
          open: true,
          severity: "error",
          message: "Something Went Wrong!",
        });
      }
    } else {
      alertMessage.setAlert({
        ...alertMessage,
        open: true,
        severity: "error",
        message: "Required Field Are Empty!",
      });
    }
  };

  const isValid = (obj) => {
    let objKey = Object.keys(obj);
    if (objKey.length > 0) {
      let g = objKey.map((l) =>
        obj[`${l}`]?.trim()?.length === 0 ? false : true
      );
      // console.log(g.includes(false));
      return !g.includes(false);
    } else {
      return false;
    }
  };

  const closeFilter = () => {
    setCloseFilter(false);
  };

  const OnFilterClicked = () => {
    setCloseFilter(true);
  };

  const OnArrayHandle = (key, value) => {
    setArraysType({ ...arraysType, [key]: value });
  };

  const handleSchema = (key, value) => {
    setsampleMetaJson({ ...sampleMetaJson, [key]: value });
  };

  const handleIsRequired = (key, value) => {
    setisRequiredJson({ ...isRequiredJson, [key]: value });
  };

  // const handleFormat = (event, newFormats) => {
  //   setFilterArr(newFormats);
  // };

  const readreportsType = () => {
    var readObj = {
      db_name: "primarycare",
      entity: "CodingMaster",
      filter: "CodingMaster.Type=='GRPREPORTS'",
      return_fields: "CodingMaster",
    };
    var readconfig = {
      method: "post",
      url: process.env.REACT_APP_API_URL_READ,
      header: { "Content-Type": "application/json" },
      data: readObj,
    };
    axios(readconfig)
      .then((res) => {
        setreportgroupType(res.data.result);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const readReportType = () => {
    var readObj = {
      db_name:config.DB_NAME,
      entity: "generalmaster",
      filter: "generalmaster.gentype=='UIREPORTTYPE'",
      return_fields: "generalmaster",
    };
    var readconfig = {
      method: "post",
      url: process.env.REACT_APP_API_URL_READ,
      header: { "Content-Type": "application/json" },
      data: readObj,
    };
    axios(readconfig)
      .then((res) => {
        setreportTypeArr(res.data.result);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    readReportType();
    readreportsType();
  }, []);

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }

    if (activeStep === 2) {
      setOpen(false);

      setActiveStep(0);

      // navigation(-1);

      // setFinishbutton(false);
    } else {
      setOpen(false);
    }
  };
  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  /////Next button function////
  const GenerateReport = (reportid) => {
    debugger;
    let data = {
      reportid: reportIdForJasper,
      inputparams: finalList,
      result: [],
    };
    console.log(data);
    var registerconfig = {
      method: "post",
      url: process.env.REACT_APP_API_GENERATEREPORT,

      header: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(registerconfig)
      .then((res) => {
        if (res.status === 200) {
          //setOpen(true);
          var downloadUrl = res.data.downloadUrl;
          setjasperdownloadurl(downloadUrl);
          setAlertStatus("success");
          setAlertmsg("Data is Registered Successfully");
          axios(res.data.downloadUrl, {
            method: "GET",
            responseType: "blob", //Force to receive data in a Blob Format
          })
            .then((response) => {
              //Create a Blob from the PDF Stream
              const file = new Blob([response.data], {
                type: "application/pdf",
              });
              //Build a URL from the file
              const fileURL = URL.createObjectURL(file);
              //Set this URL to State
              setPdf(fileURL);
              //window.open(fileURL);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((err) => {
        console.error(err);
        alertMessage.setAlert({
          ...alertMessage,
          open: true,
          severity: "error",
          message: "Oops Something went wrong",
        });
      });
    setPdfView(true);
  };
  const onHandleChange = (key, value) => {
    setQueryobj({ ...QueryObj, [key]: value });
  };

  const onFlexResetClicked = () => {
    var readQ = JSON.parse(readquery);
    delete readQ.filter;
    readFilterQuery(readQ);
  };

  const IsValid = (obj) => {
    if (isAnd && Object.keys(MetaJson)?.length > 0) {
      let objKey = Object.keys(obj);
      if (objKey.length > 0) {
        let g = objKey.map((l) =>
          obj[`${l}`]?.trim()?.length === 0 ? false : true
        );
        // console.log(g.includes(false));
        return !g.includes(false);
      } else {
        return false;
      }
    } else if (Object.keys(isRequiredJson)?.length > 0) {
      let objKey = Object.keys(obj);
      let g = objKey.map((l) => {
        if (isRequiredJson[`${l}`]) {
          if (typeof sampleMetaJson[`${l}`]) {
            return obj[`${l}`]?.length === 0 ? false : true;
          } else {
            return obj[`${l}`]?.trim()?.length === 0 ? false : true;
          }
        }
        return obj;
      });

      // console.log(g.includes(false));
      return !g.includes(false);
    } else {
      let objKey = Object.keys(obj);
      let g = objKey.map((l) => {
        if (typeof sampleMetaJson[`${l}`]) {
          return obj[`${l}`]?.length === 0 ? false : true;
        } else {
          return obj[`${l}`]?.trim()?.length === 0 ? false : true;
        }
      });
      // console.log(g.includes(false));
      return !g.includes(false);
    }
    /* else if (isOr) {
      let objKey = Object.keys(QueryObj);
      let g = objKey.map((l) =>
        QueryObj[`${l}`]?.trim()?.length === 0 ? false : true
      );
      console.log(g.some(false));
      return g.includes(false);
    } */
  };

  const onFlexFilterClicked = async () => {
    /* console.log(QueryObj);
    let obj = Object.keys(QueryObj).forEach((key) => {
      if (QueryObj[key] === "") {
        delete QueryObj[key];
      }
    }); */
    debugger;
    let valid = IsValid(QueryObj);

    if (valid) {
      var data = QueryObj;

      // let filterMap=filterArr.map(l=>Object)
      var readQ = JSON.parse(readquery);

      if (Object.keys(readQ).includes("filter")) {
        if (typeof readQ.filter === "object") {
          if (isMultipleEntities) {
            let getFiltervalues = Object.keys(readQ.filter).map((list) => {
              var strings = readQ.filter[list];
              var getad = strings.split("@").join(" ").split(" ");

              let newData = Object.keys(data).map((l) => {
                let value = l.replaceAll(`${list}_`, "");
                return { [value]: data[l] };
              });

              let newObj = Object.assign({}, ...newData);

              getad.map((e, i) =>
                Object.keys(newObj).map((l) => {
                  // debugger;
                  if (l === e) {
                    if (sampleMetaJson[`${list}_${l}`] === "string") {
                      return (getad[i] = "'" + newObj[e] + "'");
                    } else if (Array.isArray(newObj[e])) {
                      if (arraysType[`${l}`] === "string") {
                        let d = newObj[e].map((l) => "'" + l + "'");
                        return (getad[i] = "[" + d + "]");
                      } else {
                        return (getad[i] = "[" + newObj[e] + "]");
                      }
                    } else {
                      return (getad[i] = newObj[e]);
                    }
                  }
                  return getad;
                })
              );
              console.log(getad.join(" "));
              return { [list]: getad.join(" ") };
            });

            // console.log(getFiltervalues);
            let filterObj = Object.assign({}, ...getFiltervalues);
            let existingFilter = readQ.filter;
            Object.keys(existingFilter).forEach((key) =>
              Object.keys(filterObj).forEach((l) => {
                if (key === l) {
                  return (existingFilter[key] = filterObj[key]);
                } else {
                  return existingFilter[key];
                }
              })
            );

            //  Object.keys(readQ.filter)[0]
            // let entity = Object.keys(readQ.filter)[0];
            var updatedFilterQuery = {
              ...readQ,
              filter: existingFilter,
            };
            readFilterQuery(updatedFilterQuery);
          } else {
            var strings = readQ.filter[Object.keys(readQ.filter)[0]];
            var getad = strings.split("@").join(" ").split(" ");
            getad.map((e, i) =>
              Object.keys(data).map((v) => {
                if (v === e) {
                  if (sampleMetaJson[`${v}`] === "string") {
                    return (getad[i] = "'" + data[e] + "'");
                  } else if (Array.isArray(data[e])) {
                    if (arraysType[`${v}`] === "string") {
                      let d = data[e].map((l) => "'" + l + "'");
                      return (getad[i] = "[" + d + "]");
                    } else {
                      return (getad[i] = "[" + data[e] + "]");
                    }
                  } else {
                    return (getad[i] = data[e]);
                  }
                }
                return getad;
              })
            );

            console.log(getad.join(" "));
            //  Object.keys(readQ.filter)[0]
            let entity = Object.keys(readQ.filter)[0];
            var updatedFilterQuery1 = {
              ...readQ,
              filter: {
                ...readQ.filter,
                [entity]: getad.join(" "),
              },
            };
            readFilterQuery(updatedFilterQuery1);
          }
        } else if (typeof readQ.filter === "string") {
          var strings1 = readQ.filter;
          var getad1 = strings1.split("@").join(" ").split(" ");
          getad1.map((e, i) =>
            Object.keys(data).map((v) => {
              if (v === e) {
                if (sampleMetaJson[`${v}`] === "string") {
                  return (getad1[i] = "'" + data[e] + "'");
                } else if (Array.isArray(data[e])) {
                  if (arraysType[`${v}`] === "string") {
                    let d = data[e].map((l) => "'" + l + "'");
                    return (getad1[i] = "[" + d + "]");
                    // let d = JSON.stringify(data[e]);
                    // return (getad1[i] = d);
                    // return (getad1[i] = data[e]);
                  } else {
                    return (getad1[i] = "[" + data[e] + "]");
                  }
                } else {
                  return (getad1[i] = data[e]);
                }
              }
              return getad1;
            })
          );

          console.log(getad1.join(" "));
          var updatedFilterQuery2 = { ...readQ, filter: getad1.join(" ") };
          readFilterQuery(updatedFilterQuery2);
        }
      }
    } else {
      alertMessage.setAlert({
        ...alertMessage,
        open: true,
        severity: "error",
        message: "Required Field Are Empty!",
      });
    }
  };

  const readFilterQuery = async (temp) => {
    var config = {
      method: "post",
      url: process.env.REACT_APP_API_URL,
      header: { "Content-Type": "application/json" },
      data: temp,
    };
    // console.log("config of read", config);
    await axios(config)
      .then((res) => {
        // console.log(" for data reading", res.data.result);
        var report = {
          dataSource: {
            data: res.data.result,
          },
          options: {
            grid: {
              type: "flat",
              showGrandTotals: "off",
              showTotals: "off",
            },
          },
        };
        // console.log(report);
        // setQueryobj({});
        closeFilter();
        if (pivot.current !== null) {
          pivot.current.flexmonster.setReport(report);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const filterStringtoobj = (filter, entity) => {
    let getarray = filter
      .replaceAll("'", '"')
      .replaceAll(`${entity}.`, '"')
      .replaceAll("==", '":')
      .replaceAll("!==", '":')
      .replaceAll("&&", '",')
      .replaceAll("||", '",')
      .replaceAll("AND", '",')
      .replaceAll("OR", '",')
      .replaceAll("@", '"')
      .replaceAll(" ", "");
    let final = "{" + getarray + '"}';

    return final;
  };

  const getQueryObject = (string, entity, isMultipleEntity) => {
    debugger;
    if (isMultipleEntity) {
      setIsAnd(string.includes("&&"));
      setIsOr(string.includes("||"));
      let final = filterStringtoobj(string, entity);
      // setQueryFilter(JSON.parse(final));
      const arrayKeys = Object.keys(JSON.parse(final));
      let object = {};
      arrayKeys.forEach((key) => {
        object[`${entity}_${key}`] = "";
      });
      // setQueryobj(object);
      return object;
    } else {
      setIsAnd(string.includes("&&"));
      setIsOr(string.includes("||"));
      let final = filterStringtoobj(string, entity);
      setQueryFilter(JSON.parse(final));
      const arrayKeys = Object.keys(JSON.parse(final));
      let object = {};
      arrayKeys.forEach((key) => {
        object[key] = "";
      });
      setQueryobj(object);
      return object;
    }
  };

  const getQueryObjectForArray = (string, entity, isMultipleEntity) => {
    if (isMultipleEntity) {
      let stringOfArr = string
        .replaceAll(" any in ", '":"')
        .replaceAll(" any == ", '":"')
        .replaceAll(" any== ", '":"')
        .replaceAll(" ANY ==", '":"')
        .replaceAll(" ANY== ", '":"')
        .replaceAll(" in ", "")
        .replaceAll(" ANY in", '":"')
        .replaceAll(" IN ", '":"')
        .replaceAll(" ", "")
        .replaceAll("@", "")
        .replaceAll("==", '":"')
        .replaceAll(`${entity}.`, "")
        .replaceAll("&&", '","');
      let value = '{"' + stringOfArr + '"}';
      //  setQueryFilter(JSON.parse(value));
      const arrayKeys = Object.keys(JSON.parse(value));
      let object = {};
      arrayKeys.forEach((key) => {
        object[`${entity}_${key}`] = "";
      });
      //  setQueryobj(object);
      return object;
    } else {
      let stringOfArr = string
        .replaceAll(" any in ", '":"')
        .replaceAll(" any == ", '":"')
        .replaceAll(" any== ", '":"')
        .replaceAll(" ANY ==", '":"')
        .replaceAll(" ANY== ", '":"')
        .replaceAll(" in ", "")
        .replaceAll(" ANY in", '":"')
        .replaceAll(" IN ", '":"')
        .replaceAll(" ", "")
        .replaceAll("@", "")
        .replaceAll("==", '":"')
        .replaceAll(`${entity}.`, "")
        .replaceAll("&&", '","');
      let value = '{"' + stringOfArr + '"}';
      setQueryFilter(JSON.parse(value));
      const arrayKeys = Object.keys(JSON.parse(value));
      let object = {};
      arrayKeys.forEach((key) => {
        object[key] = "";
      });
      setQueryobj(object);
      return object;
    }
  };

  const getFilterFormQuery = async (readquery) => {
    if (Object.keys(readquery).includes("filter")) {
      let isMultipleEntity = readquery?.entity?.includes(",");
      let filter = readquery.filter;
      let metaDataJson = await getMetaSamepleJson(
        readquery.db_name,
        readquery.entity
      );
      //metaDataJson api will return read queries schema(value type)
      let metaData = {};
      setsampleMetaJson(metaData);
      if (isMultipleEntity) {
        if (typeof filter === "object") {
          let entities = readquery?.entity?.split(",");
          let getMultipleObj = entities.map((entity) => {
            let getFormObject = filter[`${entity}`];
            if (getFormObject.includes("@")) {
              if (
                getFormObject.includes("@") &&
                (getFormObject.includes("ANY IN") ||
                  getFormObject.includes("any in") ||
                  getFormObject.includes("any ==") ||
                  getFormObject.includes("ANY ==") ||
                  getFormObject.includes("ANY==") ||
                  getFormObject.includes(" in ") ||
                  getFormObject.includes(" IN ") ||
                  getFormObject.includes("any=="))
              ) {
                // let l = getFormObject.includes("@");
                let object = getQueryObjectForArray(
                  getFormObject,
                  entity,
                  isMultipleEntity
                );
                return object;
              } else {
                // let l = getFormObject.includes("@");
                let object = getQueryObject(
                  getFormObject,
                  entity,
                  isMultipleEntity
                );
                return object;
              }
            }
            // else {
            //   let obj = {};
            //   let l = false;
            //   return { metaData, obj, l, entity };
            // }
            return object;
          });

          var object = Object.assign({}, ...getMultipleObj);
          setIsMultipleEntities(true);
          setQueryFilter(object);
          setQueryobj(object);
          let l = true;
          return { metaData, object, l };
        }
      } else {
        if (typeof filter === "string") {
          setIsMultipleEntities(false);
          if (filter.includes("@")) {
            if (
              filter.includes("@") &&
              (filter.includes("ANY IN") ||
                filter.includes("any in") ||
                filter.includes("any ==") ||
                filter.includes("ANY ==") ||
                filter.includes("ANY==") ||
                filter.includes(" in ") ||
                filter.includes(" IN ") ||
                filter.includes("any=="))
            ) {
              let l = filter.includes("@");
              let object = getQueryObjectForArray(filter, readquery.entity);
              return { metaData, object, l };
            } else {
              let l = filter.includes("@");
              let object = getQueryObject(filter, readquery.entity);
              return { metaData, object, l };
            }
          } else {
            let obj = {};
            let l = false;
            return { metaData, obj, l };
          }
        } else if (typeof filter === "object") {
          setIsMultipleEntities(false);
          let getFormObject = filter[readquery.entity];
          if (getFormObject.includes("@")) {
            if (
              getFormObject.includes("@") &&
              (getFormObject.includes("ANY IN") ||
                getFormObject.includes("any in")) &&
              (getFormObject.includes("in") || getFormObject.includes("IN"))
            ) {
              let l = getFormObject.includes("@");
              let object = getQueryObjectForArray(
                getFormObject,
                readquery.entity
              );
              return { metaData, object, l };
            } else {
              let l = getFormObject.includes("@");
              let object = getQueryObject(getFormObject, readquery.entity);

              return { metaData, object, l };
            }
          } else {
            let obj = {};
            let l = false;

            return { metaData, obj, l };
          }
        }
      }
    } else {
      let metaData = {};
      let obj = {};
      let l = false;
      setIsMultipleEntities(false);
      return { metaData, obj, l };
    }
  };

  const validSchema = (obj) => {
    let objKey = Object.keys(obj);
    if (objKey.length > 0) {
      let g = objKey.map((l) =>
        obj[`${l}`]?.trim()?.length === 0 ? false : true
      );
      return !g.includes(false);
    } else {
      return false;
    }
  };
  const handleView = React.useCallback((inputparams, reportid) => {
    console.log(inputparams, reportid);
    setListParams(inputparams);
  }, []);
  const handleNext = async () => {
    debugger;
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    if (activeStep === 1 && readquery.length !== 0 && schema.length !== 0) {
      if (readquery.length !== 0) var temp = JSON.parse(readquery);
      delete temp.filter;

      if (template.reportType === "flexmonster") {
        var { metaData, object, l } = await getFilterFormQuery(
          JSON.parse(readquery)
        );
      }

      if (template.reportType === "jasper" && runRegister) {
        debugger;
        var data = new FormData();

        console.log("data to be sent to backend", data);
        let getInputParamsFromFilter = [];
        let formatedFilter = [];
        let formatedFilterToDb;
        let jasperFilter = JSON.parse(readquery);
        if (jasperFilter.filter) {
          console.log(
            "show me the filter",
            JSON.stringify(jasperFilter.filter).replace('"@', "@")
          );

          Object.values(jasperFilter.filter).forEach((filter) => {
            if (filter.includes("@")) {
              getInputParamsFromFilter.push(filter);

              console.log(filter, typeof filter);
              formatedFilter.push(filter);
            }
          });
          formatedFilterToDb = JSON.stringify(jasperFilter.filter).replaceAll(
            '"@',
            "@"
          );
          formatedFilterToDb = formatedFilterToDb.replaceAll("'@", "@");
          formatedFilterToDb = formatedFilterToDb.replaceAll('",', ",");
          formatedFilterToDb = formatedFilterToDb.replaceAll("',", ",");
          formatedFilterToDb = formatedFilterToDb.replaceAll("'} ", "}");
          formatedFilterToDb = formatedFilterToDb.replaceAll('"}', "}");
          jasperFilter.filter = formatedFilterToDb;
        }
        console.log("formated filted", JSON.stringify(jasperFilter));
        setJasperInputParams(getInputParamsFromFilter);
        console.log("v", JSON.stringify(jasperFilter));
        console.log("final getinputparams", getInputParamsFromFilter);
        data.append("jrxmltemplate", filedata.filedata);
        data.append("reportname", template.reportName);
        data.append("rolecaccess", template.rolecaccess);
        data.append("reportcacheduration", template.reportcacheduration);
        data.append("query", readyQueryJasper);
        data.append("inputparams", `[${getInputParamsFromFilter.toString()}]`);
        data.append("queryschema", schema);
        data.append("parentreport", "");
        data.append("fileurl", template.fileurl);

        var config = {
          method: "post",
          url: process.env.REACT_APP_API_REGISTERREPORT,
          headers: {},
          data: data,
        };

        axios(config)
          .then((res) => {
            if (res.status === 200) {
              //setOpen(true);
              if (res.data.Code === 201) {
                var reportId = res.data.Result[0].properties.doc.reportid;
                setReportIdForJasper(reportId);
                setregreportid(reportId);
                handleView(getInputParamsFromFilter, reportId);
              }
              setAlertStatus("success");
              setAlertmsg("Data is Registered Successfully");
            }
          })
          .catch((err) => {
            console.error(err);
            setOpen(true);
            setAlertStatus("error");
            setAlertmsg("Oops Something went wrong");
          });
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
      }

      if (
        template.reportType === "flexmonster" &&
        Object.keys(metaData)?.length === 0 &&
        l
      ) {
        var config = {
          method: "post",
          url: process.env.REACT_APP_API_URL,
          header: { "Content-Type": "application/json" },
          data: temp,
        };
        axios(config)
          .then((res) => {

            var report = {
              dataSource: {
                data: res.data.result,
              },
              options: {
                grid: {
                  type: "flat",
                  showGrandTotals: "off",
                  showTotals: "off",
                },
              },
            };
            if (pivot.current !== null) {
              pivot.current.flexmonster.setReport(report);
            }
          })
          .catch((err) => {
            console.error(err);
          });
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        let cloneSteps = steps;
        let isStepsIncludesMapping = cloneSteps.includes("Mapping Filter");
        if (isStepsIncludesMapping) {
          console.log(cloneSteps);
          setSteps(cloneSteps);
        } else {
          cloneSteps.splice(cloneSteps.length - 1, 0, "Mapping Filter");
          console.log(cloneSteps);
          setSteps(cloneSteps);
        }
        setsampleMetaJson(object);

        setFourthStep(true);
        setSkipped(newSkipped);
      }
    } else if (
      activeStep === 2 &&
      steps[activeStep] === "Mapping Filter"
    ) {
      let isValid = validSchema(sampleMetaJson);
      if (isValid) {
        var temp2 = JSON.parse(readquery);
        delete temp2.filter;
        var d = {
          method: "post",
          url: process.env.REACT_APP_API_URL,
          header: { "Content-Type": "application/json" },
          data: temp2,
        };
        axios(d)
          .then((res) => {
            var report = {
              dataSource: {
                data: res.data.result,
              },
              options: {
                grid: {
                  type: "flat",
                  showGrandTotals: "off",
                  showTotals: "off",
                },
              },
            };
            if (pivot.current !== null) {
              pivot.current.flexmonster.setReport(report);
            }
          })
          .catch((err) => {
            console.error(err);
          });
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
      } else {
        alertMessage.setAlert({
          ...alertMessage,
          open: true,
          severity: "error",
          message: "Required Field Are Empty!",
        });
      }
    } else if (activeStep === 0 && template.reportType === "superset") {
      let superSetSteps = ["Report-Details", "Final"];
      setSteps(superSetSteps);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    } else if (
      activeStep === 0 &&
      (template.reportName.trim().length === 0 ||
        template.reportType.trim().length === 0)
    ) {
      setOpen(true);
      setAlertStatus("warning");
      setAlertmsg("Please enter the details");
    } else if (
      (readquery.length === 0 && activeStep === 1) ||
      (schema.length === 0 && activeStep === 1)
    ) {
      if (schema.length === 0) {
        setOpen(true);
        setAlertStatus("warning");
        setAlertmsg("Please press the generate button");
      } else {
        setOpen(true);
        setAlertStatus("warning");
        setAlertmsg("Please enter the details");
      }
    } else {
      var isvalidate = "true";
      if (template.reportType === "jasper") {
        if (
          template.fileurl === undefined ||
          template.fileurl.trim() === "" ||
          template.fileurl === null
        ) {
          setOpen(true);
          setAlertStatus("warning");
          setAlertmsg("Please enter the fileurl");
          isvalidate = "false";
        }

        if (
          jrxmltemplate === undefined ||
          jrxmltemplate.trim() === "" ||
          jrxmltemplate === null
        ) {
          setOpen(true);
          setAlertStatus("warning");
          setAlertmsg("Please upload the file template");
          isvalidate = "false";
        }
      }
      if (isvalidate === "true") {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
        converter();
      }
    }
  };

  const jasperFilter = () => {
    let getfilter = JSON.parse(readquery);
    console.log("readquery", getfilter.filter);
    console.log("readquery", typeof getfilter.filter);
    let inputpar = [];
    if (typeof getfilter.filter === "string") {
      console.log("this is for query that has string");
    } else if (typeof getfilter.filter === "object") {
      Object.values(getfilter.filter).forEach((e) => {
        if (e.includes("@")) {
          inputpar.push(e.substring(e.indexOf("@")));
        }
      });
    }
    setJasperInputParams(inputpar);
  };

  ///Back button function///

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onReportComplete = () => {
    console.log(">>>>", pivot.current.flexmonster.getReport());
  };

  /////Finish button function///
  const handleFinish = () => {
    var result = {};
    var edithead;
    if (template.reportType === location.state.reporttype) {
      edithead = fmheadfoot;
    } else {
      var headerchange =
        location.state.fmheadfoot.header === undefined
          ? undefined
          : location.state.fmheadfoot.header.split("<h1");
      var headjoin = `<h1 style='width:80%;font-size:50px;text-align:center;'>${template.reportName}</h1></div><p>${headername}</p>`;
      var headresult =
        headerchange === undefined ? undefined : headerchange.concat(headjoin);
      // console.log(headresult);
      edithead = {
        header: headresult,
        footer: location.state.fmheadfoot.footer,
        pageOrientation: location.state.fmheadfoot.pageOrientation,
      };
    }

    var sendtobackend;
    if (template.reportType === "jasper") {
      jasperFilter();
      sendtobackend = {
        db_name:config.DB_NAME,
        entity: config.ENTITY,
        is_metadata: false,
        filter: { _key: location.state._key },
        doc: {
          reportid: template.reportId,
          reportname: template.reportName,
          readquery: readyQueryJasper,
          readjsonschema: schema,
          reporttype: template.reportType,
          jrxmlfileid: fileid.fileid,
          jrxmltemplate: jrxmltemplate,
          jrxmlfilename: filedata.name,
          rolecaccess: template.rolecaccess,
          parentreport: template.parentreport,
          fileurl: template.fileurl,
          inputParams: jasperInputParams,
          reportcacheduration: template.reportcacheduration,
          clientid: sessionStorage.getItem("clientid"),
          metadataid: sessionStorage.getItem("metadataId"),
          projectid: sessionStorage.getItem("projectid"),
        },
      };
    } else if (template.reportType === "queryreport") {
      sendtobackend = {
        db_name:config.DB_NAME,
        entity: config.ENTITY,
        is_metadata: false,
        filter: { _key: location.state._key },
        doc: {
          reportid: template.reportId,
          reportname: template.reportName,
          readquery: readquery,
          readjsonschema: schema,
          reporttype: template.reportType,
          reportGrpCode:
            template.reportGrpObj === undefined
              ? ""
              : template.reportGrpObj.code,
          reportGrpid:
            template.reportGrpObj === undefined
              ? ""
              : template.reportGrpObj._id,
          reportGrpName:
            template.reportGrpObj === undefined
              ? ""
              : template.reportGrpObj.display,
          template: result,
          fmrender,
          fmheadfoot,
          clientid: sessionStorage.getItem("clientid"),
          metadataid: sessionStorage.getItem("metadataId"),
          projectid: sessionStorage.getItem("projectid"),
        },
      };
    } else if (template.reportType === "superset") {
      sendtobackend = {
        db_name:config.DB_NAME,
        entity: config.ENTITY,
        is_metadata: false,
        filter: { _key: location.state._key },
        doc: {
          reportid: template.reportId,
          reportname: template.reportName,
          reporttype: template.reportType,
          url: template.url,
          template: result,
          clientid: sessionStorage.getItem("clientid"),
          metadataid: sessionStorage.getItem("metadataId"),
          projectid: sessionStorage.getItem("projectid"),
        },
      };
    } else {
      var temp = {};
      temp = pivot.current.flexmonster.getReport({
        withGlobals: true,
      });
      console.log("tempp", pivot.current.flexmonster.getReport());
      delete temp.dataSource;
      var dataSource = { dataSource: { data: [], type: "json" } };

      result = Object.assign(temp, dataSource);
      // console.log("function called", result);
      sendtobackend = {
        db_name:config.DB_NAME,
        entity: config.ENTITY,
        is_metadata: false,
        filter: { _key: location.state._key },
        doc: {
          reportid: template.reportId,
          reportname: template.reportName,
          readquery: readquery,
          readjsonschema: schema,
          reporttype: template.reportType,
          querySchema: sampleMetaJson,
          filterObj: QueryObj,
          template: result,
          fmrender,
          fmheadfoot: edithead,
          clientid: sessionStorage.getItem("clientid"),
          metadataid: sessionStorage.getItem("metadataId"),
          projectid: sessionStorage.getItem("projectid"),
        },
      };
    }
    var datas = [sendtobackend];

    // console.log("data to be sent to backend", datas);
    var config = {
      method: "post",
      url: config.UPSERT,
      header: { "Content-Type": "application/json" },
      data: datas,
    };
    axios(config)
      .then(async (res) => {
        // console.log(res.status);
        if (res.data.Code === 201) {
          let unique_id = res.data.Result[0]?.properties?.doc._id;
          let _key = res.data.Result[0]?.properties?.doc._key;
          await CheckRepoHasData(_key, template.reportName, unique_id);

          alertMessage.setAlert({
            ...alertMessage,
            open: true,
            severity: "success",
            message: "Data Updated Successfully!",
          });
          navigation(-1);
          setReadquery("");
          setSchema("");
          setTemplate({
            reportId: String(),
            reportName: String(),
          });
          // setActiveStep(0);
          console.log("show", localStorage.getItem("url"));
          localStorage.setItem("reload", "true");
          setPdfView(false);
        }
      })
      .catch((err) => {
        console.error(err);
        setOpen(true);
        setAlertStatus("error");
        setAlertmsg("Oops Something went wrong");
      });
    // console.log("called ");
  };

  const CheckRepoHasData = async (_key, repName, unique_id) => {
    let RepoData = await getRepoid(unique_id);
    if (RepoData.length > 0) {
      await UpdateRepo(_key, repName, "", true);
    } else {
      await upsertRepo(template.reportName, "", unique_id);
    }
  };

  ////JSON formatter function///
  const converter = () => {
    if (template.reportType === "jasper") {
      // GetInputParamsAndValues(null, readquery, function () {
      //   var parsed = JSON.parse(readquery);
      //   const schemas = toJsonSchema(parsed);
      //   var pretty = JSON.stringify(schemas, undefined, 4);
      //   var pretty1 = JSON.stringify(parsed, undefined, 4);
      //   setSchema(pretty);
      //   setReadquery(pretty1);
      // });
      try {
        let newreadquery;
        if (readquery.includes("@")) {
          newreadquery = readquery.split(",").join("->").split("->");
        }
        // newreadquery.map((e,i) => e.includes("@") && !e.includes('"@') && newreadquery[i]=`"${e}"`);
        newreadquery.map((e, i) => {
          if (e.includes("@") && !e.includes('"@')) {
            e = e.trim();
            console.log(e);
            e = e.replace("@", '"@');
            var replaceValue;
            console.log(e[e.length - 1] === "}");
            if (e[e.length - 1] === "}") {
              e = e.replace("}", '"}');
              e = e.replaceAll("\n", "");

              replaceValue = "";
            } else {
              replaceValue = '"';
            }

            newreadquery[i] = e + replaceValue;
          }
          return newreadquery;
        });
        console.log("jk", newreadquery, newreadquery.join(","));
        var parsed = JSON.parse(newreadquery.join(","));
        Object.values(parsed.filter).forEach((e) => console.log(e));
        const schemas = toJsonSchema(parsed);
        var pretty = JSON.stringify(schemas, undefined, 4);
        var pretty1 = JSON.stringify(parsed, undefined, 4);
        setSchema(pretty);
        setReadquery(pretty1);
      } catch (err) {
        console.error(err);
        alertMessage.setAlert({
          ...alertMessage,
          open: true,
          severity: "error",
          message: "Not A Valid JSON Format",
        });
      }
    } else {
      parsed = JSON.parse(readquery);
      const schemas = toJsonSchema(parsed);
      pretty = JSON.stringify(schemas, undefined, 4);
      pretty1 = JSON.stringify(parsed, undefined, 4);
      setSchema(pretty);
      setReadquery(pretty1);
    }
  };

  const AddParameterInFilter = (inputresp, filterstrparamasArr) => {
    var rstArr = inputresp.data.ast;
    var subNodeArr = rstArr[0].subNodes;
    for (var i = 0; i < subNodeArr.length; i++) {
      var Nodetype = subNodeArr[i].type;
      if (Nodetype === "filter") {
        var filtersubNode = subNodeArr[i].subNodes;
        var resultqueryObj = {};
        var respfilter = Formfilterquerywithparam(
          filtersubNode,
          subNodeArr[i].type,
          resultqueryObj,
          filterstrparamasArr
        );
        respfilter["parentcondition"] = filtersubNode[0].type;
        return respfilter;
      }
    }
    // return "";
  };

  const Formfilterquerywithparam = (
    filtersubNode,
    filterparentnodeType,
    resultqueryObj,
    filterstrparamasArr
  ) => {
    var query = "";
    if (filtersubNode.length > 0) {
      for (var i = 0; i < filtersubNode.length; i++) {
        var filtersubnodeType = filtersubNode[i].type;
        if (filtersubnodeType !== "quantifier") {
          if (
            filtersubnodeType.includes("in") ||
            filtersubnodeType.includes("and") ||
            filtersubnodeType.includes("==") ||
            filtersubnodeType.includes("or")
          ) {
            var filterlogicalsubnode = filtersubNode[i].subNodes;
            Formfilterquerywithparam(
              filterlogicalsubnode,
              filtersubnodeType,
              resultqueryObj,
              filterstrparamasArr
            );
          } else {
            var fieldName = filtersubNode[i].name;

            var subnodetype = filtersubNode[i].type;
            var fieldrefsubNode = "";
            var reffieldName = "";
            if ("subNodes" in filtersubNode[i]) {
              fieldrefsubNode = filtersubNode[i].subNodes[0];
              reffieldName = fieldrefsubNode.name;
            }

            if (subnodetype !== "value" && subnodetype !== "array") {
              if (query.trim().startsWith("@")) {
                query =
                  query +
                  " " +
                  filterparentnodeType +
                  " " +
                  reffieldName +
                  "." +
                  fieldName;
              } else if (!query.includes(filterparentnodeType)) {
                if (reffieldName === "DOCUMENT") {
                  query =
                    query +
                    " " +
                    reffieldName +
                    "(" +
                    GetDocFunctionattfilter(fieldrefsubNode.subNodes) +
                    ")." +
                    fieldName +
                    " " +
                    filterparentnodeType +
                    " ";
                } else if (reffieldName === undefined) {
                  var reffielddocsubnodes = fieldrefsubNode.subNodes;
                  var reffielddocsubnodesName = reffielddocsubnodes[0].name;
                  query =
                    query +
                    " " +
                    reffielddocsubnodesName +
                    "(" +
                    GetDocFunctionattfilter(reffielddocsubnodes[0].subNodes) +
                    ")[" +
                    reffielddocsubnodes[1].value +
                    "]." +
                    fieldName +
                    " " +
                    filterparentnodeType +
                    " ";
                } else if (
                  query.trim().startsWith("@") ||
                  query.trim().startsWith("[@")
                ) {
                  query =
                    query +
                    " " +
                    filterparentnodeType +
                    "" +
                    reffieldName +
                    "." +
                    fieldName +
                    " ";
                } else {
                  query =
                    query +
                    " " +
                    reffieldName +
                    "." +
                    fieldName +
                    " " +
                    filterparentnodeType +
                    " ";
                }
              } else {
                query = query + " " + reffieldName + "." + fieldName + " ";
              }
            } else {
              var val = "";
              if (subnodetype === "array") {
                val = filtersubNode[i].subNodes;
                var arrval = [];
                for (var k = 0; k < val.length; val++) {
                  arrval.push(val[k].value);
                }
                if (filtersubNode[i - 1] !== undefined) {
                  filterstrparamasArr.push(filtersubNode[i - 1].name);
                  query = query + " [@" + filtersubNode[i - 1].name + "]";
                } else {
                  //if value comes first
                  //  val=filtersubNode[i].value
                  var obj = {};
                  if (filtersubNode[i].type === "array") {
                    obj[filtersubNode[i + 1].name] = arrval;
                  }
                  filterstrparamasArr.push(obj);
                  query = query + " [@" + filtersubNode[i + 1].name + "]";
                }
              } else {
                val = filtersubNode[i].value;
                obj = {};
                obj[filtersubNode[i - 1].name] = val;
                filterstrparamasArr.push(obj);
                if (filtersubNode[i - 1].name === "activestatus") {
                  query = query + true;
                } else {
                  query = query + " @" + filtersubNode[i - 1].name;
                }
              }
            }
          }
        }
      }
      if (query !== "") {
        var rsltqurykeys = Object.keys(resultqueryObj);
        resultqueryObj["filter" + rsltqurykeys.length] = query;
        resultqueryObj["filterparam" + rsltqurykeys.length] =
          filterstrparamasArr;
      }
    }

    return resultqueryObj;
  };

  const GetDocFunctionattfilter = (inputsubnodes, resultquery) => {
    if (inputsubnodes.length > 0) {
      for (var i = 0; i < inputsubnodes.length; i++) {
        var subNodes = inputsubnodes[i].subNodes;
        for (var j = 0; j < subNodes.length; j++) {
          var fieldName = subNodes[j].name;
          var fieldRef = subNodes[j].subNodes[0].name;
          if (fieldRef === "DOCUMENT") {
            //query = query + " " + reffieldName + "(" + GetDocFunctionattfilter(fieldrefsubNode.subNodes) + ")." + " " + fieldName + " " + filterparentnodeType + " ";
            resultquery =
              fieldRef +
              "(" +
              GetDocFunctionattfilter(
                subNodes[j].subNodes[0].subNodes,
                resultquery
              ) +
              ")";
          }
          resultquery =
            (resultquery === undefined ? fieldRef : resultquery) +
            "." +
            fieldName;
        }
        return resultquery;
      }
    }
  };

  const GetInputParamsAndValues = function (value, readquery, calback) {
    //var parsed = JSON.parse(readquery);
    var config = {
      method: "post",
      url: process.env.REACT_APP_API_URL,
      header: { "Content-Type": "application/json" },
      data: JSON.parse(readquery),
    };
    axios(config).then((res) => {
      ArangoDBAPI.GetQueryInfo(res.data.query, function (resp) {
        var filterstrparamasArr = [];
        var filterrespObj = {};
        //return resp;
        // console.log(resp);
        //var readResp = ArangoDBAPI.ReadDocument(readquery);
        var inputparamresultObj = {};
        var filterstrs = AddParameterInFilter(resp, filterstrparamasArr);
        var filtersObjKeys = Object.keys(filterstrs);
        if (filtersObjKeys.length > 0) {
          for (var k = 0; k < filtersObjKeys.length; k++) {
            if (filtersObjKeys[k].includes("filterparam")) {
              var inputparamObj = filterstrs[filtersObjKeys[k]];
              if (inputparamObj.length > 0) {
                for (var l = 0; l < inputparamObj.length; l++) {
                  var inpparamObj = inputparamObj[l];
                  var inpparamObjkeys = Object.keys(inpparamObj);
                  for (var m = 0; m < inpparamObjkeys.length; m++) {
                    var inputval = inpparamObj[inpparamObjkeys[m]];
                    inputparamresultObj[inpparamObjkeys[m]] = inputval;
                  }
                }
              }
            }
          }
        }
        setinputParams(inputparamresultObj);
        calback();
        // return inputparamresultObj;
        // converter(value, resp, finalfilterquery, filterrespObj);
      });
    });
  };

  function customizeToolbar(toolbar) {
    let tabs = toolbar.getTabs();
    toolbar.getTabs = function () {
      tabs = tabs.filter(
        (tab) =>
          tab.id !== "fm-tab-connect" &&
          tab.id !== "fm-tab-save" &&
          tab.id !== "fm-tab-open"
      );
      tabs.unshift({
        id: "fm-tab-newtab2",
        title: "Header",
        icon: "<img src='https://img.icons8.com/dotty/45/000000/document-header.png' alt='Header'>",
        handler: headerHandler,
      });
      tabs.unshift({
        id: "fm-tab-newtab1",
        title: "Add",
        icon: "<img src='https://img.icons8.com/carbon-copy/45/000000/add--v1.png' alt='Add'/>",
        handler: TabHandler,
      });
      return tabs;
    };
  }
  var headerHandler = function () {
    // console.log("header");
    setDiaforhead(true);
  };
  var TabHandler = function () {
    setOpendia(true);
  };

  const closedia = () => {
    setColumn("");
    setColumnvalues("");
    setOpendia(false);
  };
  const onchangeevent = (e) => {
    var val = e.target.value;
    for (var k = 0; k < reportgroupType.length; k++) {
      var reportgroupTypeObj = reportgroupType[k];
      if (reportgroupTypeObj.code === val) {
        setTemplate({ ...template, reportGrpObj: reportgroupTypeObj });
      }
    }
  };
  /////Column value set function///

  const columnset = () => {
    // if (valueType === "formula" && columnvalue.length !== 0) {
    //   var condition = columnvalue.split("(");
    //   var combine = condition[1].split(")");
    //   console.log(combine[0]);
    //   var datamap = combine[0].split("}");
    //   var senddatamap = datamap[0].split("{").join("");
    //   // console.log(datamap[0].split("{").join(""));
    //   var columnName = column;
    //   var columnType = "string";
    //   var temp = pivot.current.flexmonster.getReport();
    //   var currentData = temp.dataSource.data;
    //   currentData[0][columnName] = { type: columnType };
    //   currentData.map((v) => {
    //     var store = {
    //       [columnName]: v[senddatamap],
    //     };
    //     return Object.assign(v, store);
    //   });
    //   temp.dataSource.data = currentData;
    //   console.log("before setreport", temp);
    //   pivot.current.flexmonster.setReport(temp);
    //   setOpendia(false);
    //   customizeCellFunction(combine[0]);
    // } else {
    setFmender([...fmrender, { columnName: column, columnvalue: columnvalue }]);
    var data = [];
    var newdata = [];
    var nomap = [];
    data = columnvalue.split("/{");
    // console.log("data", data);
    data.map((e) => {
      var len = e.length;
      return e[len - 1] === "}"
        ? newdata.push(e.replace(/}/g, ""))
        : nomap.push(e);
    });

    data = [];
    newdata.map((e) => data.push(e.replace(/{/g, "")));
    // console.log("final data", newdata);
    if (column.length !== 0) {
      var columnName = column;
      var columnType = "string";
      var temp = pivot.current.flexmonster.getReport();
      var currentData = temp.dataSource.data;
      // console.log("Column values", currentData);
      currentData[0][columnName] = { type: columnType };
      currentData.map((v) => {
        var sampltemp = [];

        var valuemap = [];
        valuemap = data ? data : newdata;
        return valuemap.map((e) => {
          var teemp = v[e];
          sampltemp.push(teemp);
          // console.log("concat", sampltemp.join());
          // console.log("nomap value", nomap.join("/"));
          var display;
          // console.log(nomap.length);
          if (nomap.length === 1) {
            display = nomap + "/" + sampltemp.join("/");
          } else {
            display = nomap.join("/") + sampltemp.join("/");
          }
          console.log("display", display);
          var store = {
            [columnName]: display,
          };
          return Object.assign(v, store);
        });
      });
      // console.log("Column test", currentData);
      temp.dataSource.data = currentData;
      if (pivot.current !== null) {
        pivot.current.flexmonster.setReport(temp);
      }
      setOpendia(false);
    } else {
      setOpen(true);
      setAlertStatus("warning");
      setAlertmsg("Enter the values");
    }
    // }
    setColumn("");
    setColumnvalues("");
  };

  const diasetpdf = () => {
    // console.log("pdf add button");
    // setPdfset(true);
    var footervalue;
    if (dateflex === "true" && pageflex === "false") {
      footervalue = `<div><p>${footercont}</p><div style='text-align: center; width:100%;'>##CURRENT-DATE##</div></div>`;
    } else if (pageflex === "true" && dateflex === "false") {
      footervalue = `<div><p>${footercont}</p><div style='text-align: center; width:100%;'>##PAGE-NUMBER##</div></div>`;
    } else if (pageflex === "true" && dateflex === "true") {
      footervalue = `<div><p>${footercont}</p><div style='text-align: center; width:100%;'><div style='text-align:left;'>##CURRENT-DATE##  </div><div >##PAGE-NUMBER##</div><div></div>`;
    } else {
      footervalue = `<p>${footercont}</p>`;
    }
    // console.log(footervalue);
    if (footervalue.length !== 0) {
      setFmheadfoot({
        header: `<div style='width:100%;display:inline-flex;'><img style='width:20%;' src='${logourl}' alt='logo'><h1 style='width:80%;font-size:50px;text-align:center;'>${template.reportName}</h1></div><p>${headername}</p>`,
        footer: footervalue,
        pageOrientation: "landscape",
      });
    } else {
      setFmheadfoot({
        header: `<div style='width:100%;display:inline-flex;'><img style='width:20%;' src='${logourl}' alt='logo'><h1 style='width:80%;font-size:50px;text-align:center;'>${template.reportName}</h1></div><p>${headername}</p>`,
        footer: footervalue,
        pageOrientation: "landscape",
      });
    }

    setDiaforhead(false);
  };
  const changeHandle = (evt) => {
    console.log(evt.target.name, evt.target.value);
    Object.assign(finalList, { [evt.target.name]: evt.target.value });
    setFinallist(finalList);
  };
  ///file upload function
  const handlefiledata = (e) => {
    setRunRegister(true);
    const reader = new FileReader();
    reader.readAsText(e.target.files[0]);
    reader.onload = () => {
      setJrmltemplate(reader.result);
      // console.log("readerfile", reader.result);
    };
    var data = new FormData();
    setFiledata(e.target.files[0]);
    data.append("files", e.target.files[0]);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_FILEUPLOAD}/${process.env.REACT_APP_DB_NAME}/11`,
      headers: {},
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        setFileid(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  //Function for pdf close
  const closediapdf = () => {
    setDiaforhead(false);
  };
  // console.log("column value : ", column);
  const handleDownload = () => {
    // var config = {
    //   method: "get",
    //   url: `${}`,
    //   header: { "Content-Type": "Content-Disposition" },
    // };
    // axios(config)
    //   .then((res) => {
    //     console.log("res", res);
    //   })
    //   .catch((err) => {
    //     console.error(err);
    //   });
  };
  // console.log("Fileidname ", fileid.length);
  // console.log("column", valueType, columnType);
  // const customizeCellFunction = (condition) => {
  //   pivot.current.flexmonster.customizeCell((cell, data) => {
  //     // if (!(data.hierarchy && data.hierarchy.uniqueName === `${column}`))
  //     //   return;
  //     // else
  //     if (data.hierarchy && data.hierarchy.uniqueName === `${column}`) {
  //       var check;
  //       if (condition) {
  //         check = "true";
  //       } else {
  //         check = "false";
  //       }
  //       var newText = `<input type="checkbox" name="checkbox" value="${
  //         data.label
  //       }" ${
  //         check === "false" ? "checked" : ""
  //       }  onchange="onCheck(event)">${column}</input>`;

  //       console.log("newText", newText);
  //       if (data.type === "value") {
  //         cell.style["z-index"] = 1;
  //         cell.text = newText;
  //       }
  //     }

  //     else if (
  //         data.hierarchy &&
  //         data.hierarchy.uniqueName === "activestatus"
  //       ) {
  //         if (data.label === "false") {
  //           //event function definition is declared in index.html file
  //           let newText = `${data.label} <button onclick="buttonclick(event)" class="buttonstyle" value=${data.label} >change</button>`;
  //           // `<button onchange="buttonclick(event)">change</button>`;
  //           if (data.type === "value") {
  //             cell.style["z-index"] = 3;
  //             cell.text = newText;
  //           }
  //         }
  //       } else if (data.hierarchy && data.hierarchy.uniqueName === "updatedby") {

  //       } else {
  //         //event function definition is declared in index.html file
  //         let newText = `<a href=${data.label} class="image" target="_blank"}><img src=${data.label} width="60px" height="50px" alt="this is image"></img></a>
  //         <input type="checkbox" name="checkbox" onchange="onCheck(event)">Value</input>`;
  //         if (data.type === "value") {
  //           cell.style["z-index"] = 1;
  //           cell.text = newText;
  //         }
  //       }
  //   });
  // };
  return (
    <div style={{ padding: "20px", height: "100%" }}>
      <Paper
        className={classes.paper}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton
            onClick={() => {
              navigation(-1);
            }}
          >
            <KeyboardBackspaceOutlinedIcon style={{ color: "black" }} />
          </IconButton>
          <div style={{ width: "100%" }}>
            <h2 style={{ textAlign: "center" }}>Report Generator</h2>
          </div>
        </div>

        <Stepper activeStep={activeStep} style={{ padding: "8px" }}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>

        <React.Fragment>
          {activeStep + 1 === 1 && (
            <Grid
              className={classes.grid1}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item lg={3} xs={12} md={6}>
                <Grid container>
                  <Grid item lg={12}>
                    <Typography variant="body1">
                      Report Id <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      disabled
                      // style={{ padding: "10px", width: "100%" }}
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      size="small"
                      value={template.reportId}
                      onChange={(e) =>
                        setTemplate({ ...template, reportId: e.target.value })
                      }
                    ></TextField>
                  </Grid>

                  <Grid item lg={12}>
                    <Typography>
                      Report Name <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      required
                      // style={{ padding: "10px", width: "100%" }}
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      size="small"
                      value={template.reportName}
                      onChange={(e) =>
                        setTemplate({ ...template, reportName: e.target.value })
                      }
                    ></TextField>
                  </Grid>
                  <Grid item lg={12}>
                    <Typography>
                      Report Type <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <FormControl fullWidth size="small" margin="dense">
                      <Select
                        required
                        // style={{ padding: "10px", width: "100%" }}
                        value={template.reportType}
                        placeholder="Select"
                        onChange={(e) =>
                          setTemplate({
                            ...template,
                            reportType: e.target.value,
                          })
                        }
                        input={<BootstrapInput />}
                        MenuProps={menuProps}
                      >
                        <MenuItem value={""}>select</MenuItem>
                        {reportTypeArr.map((item) => (
                          <MenuItem key={item.code} value={item.code}>
                            {item.description}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {template.reportType === "queryreport" ? (
                    <Grid item lg={12}>
                      <Typography>
                        Report Group<span style={{ color: "red" }}>*</span>
                      </Typography>
                      <FormControl fullWidth size="small" margin="dense">
                        <Select
                          required
                          // style={{ padding: "10px", width: "100%" }}
                          placeholder="Select"
                          value={
                            template.reportGrpObj === undefined
                              ? undefined
                              : template.reportGrpObj.reportGrpCode
                          }
                          onChange={(e) => onchangeevent(e)}
                          input={<BootstrapInput />}
                          MenuProps={menuProps}
                        >
                          <MenuItem>Select</MenuItem>
                          {reportgroupType.map((item) => (
                            <MenuItem key={item.code} value={item.code}>
                              {item.display}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  ) : null}
                  {template.reportType === "jasper" ? (
                    // <div
                    //   style={{
                    //     display: "flex",
                    //     justifyContent: "center",
                    //     alignContent: "center",
                    //     flexDirection: "column",
                    //     width: "300px",
                    //   }}
                    // >
                    <>
                      <Grid item lg={12}>
                        <Typography>Role Access</Typography>

                        <TextField
                          required
                          variant="outlined"
                          fullWidth
                          value={template.rolecaccess}
                          margin="dense"
                          size="small"
                          onChange={(e) =>
                            setTemplate({
                              ...template,
                              rolecaccess: e.target.value,
                            })
                          }
                        ></TextField>
                      </Grid>
                      <Grid item lg={12}>
                        <Typography>Report Cache Duration</Typography>

                        <TextField
                          required
                          variant="outlined"
                          fullWidth
                          margin="dense"
                          size="small"
                          value={template.reportcacheduration}
                          onChange={(e) =>
                            setTemplate({
                              ...template,
                              reportcacheduration: e.target.value,
                            })
                          }
                        ></TextField>
                      </Grid>
                      <Grid item lg={12}>
                        <Typography>Parent Report</Typography>

                        <TextField
                          required
                          // style={{ padding: "10px", width: "100%" }}
                          variant="outlined"
                          value={template.parentreport}
                          fullWidth
                          margin="dense"
                          size="small"
                          onChange={(e) =>
                            setTemplate({
                              ...template,
                              parentreport: e.target.value,
                            })
                          }
                        ></TextField>
                      </Grid>
                      <Grid item lg={12}>
                        <Typography>fileurl</Typography>

                        <TextField
                          required
                          // style={{ padding: "10px", width: "100%" }}
                          variant="outlined"
                          fullWidth
                          margin="dense"
                          size="small"
                          value={template.fileurl}
                          onChange={(e) =>
                            setTemplate({
                              ...template,
                              fileurl: e.target.value,
                            })
                          }
                        ></TextField>
                      </Grid>
                      <Grid item lg={12}>
                        <div
                          className={classes.div1}
                        >
                          <input
                            value={filedata.name}
                            disabled
                            style={{ width: "200px", margin: "5px" }}
                          />

                          <label
                            htmlFor="contained-button-file"
                            style={{ margin: "5px" }}
                          >
                            <Input
                              accept=".jrxml"
                              id="contained-button-file"
                              multiple
                              type="file"
                              onChange={handlefiledata}
                            />

                            <Button
                              variant="contained"
                              component="span"
                              size="small"
                            >
                              Upload
                            </Button>
                            {/* <Button
                              onClick={() => handleDownload()}
                              style={{ margin: "0 20px" }}
                              startIcon={<DownloadIcon />}
                            >
                              <Link
                                style={{ textDecoration: "none" }}
                                href={`${
                                  process.env.REACT_APP_API_FILEDOWNLOAD
                                }/${process.env.REACT_APP_DB_NAME}/${
                                  fileid.length === 0
                                    ? location.state.jrxmlfileid
                                    : fileid.fileid
                                }`}
                              >
                                Download
                              </Link>
                            </Button> */}
                          </label>
                        </div>
                      </Grid>
                    </>
                  ) : null}
                  {template.reportType === "superset" && (
                    <Grid item lg={12}>
                      <Typography>
                        Url<span style={{ color: "red" }}>*</span>
                      </Typography>

                      <TextField
                        required
                        // style={{ padding: "10px", width: "100%" }}
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        size="small"
                        value={template.url}
                        onChange={(e) =>
                          setTemplate({ ...template, url: e.target.value })
                        }
                      ></TextField>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}
          {activeStep + 1 === 2 && (
            <div
              className={classes.div2}
            >
              {template.reportType === "superset" ? (
                <iframe
                  title="superset"
                  src={template.url}
                  className={classes.iframe}
                ></iframe>
              ) : (
                <div
                  className={classes.div3}
                >
                  <div
                    className={classes.div4}
                  >
                    <label>READ QUERY</label>
                    <textarea
                      style={{ padding: "10px" }}
                      value={readquery}
                      onChange={(e) => {
                        setReadquery(e.target.value);
                        setReadQueryJasper(e.target.value);
                      }}
                      rows="14"
                      cols="45"
                    ></textarea>
                  </div>
                  <div
                    className={classes.div5}
                  >
                    <button onClick={() => converter()}>Generate</button>
                  </div>
                  <div
                    className={classes.div6}
                  >
                    <label>JSON SCHEMA</label>
                    <textarea
                      style={{ padding: "10px" }}
                      value={schema}
                      onChange={(e) => setSchema(e.target.value)}
                      rows="14"
                      cols="45"
                    ></textarea>
                  </div>
                </div>
              )}
            </div>
          )}
          {template.reportType === "jasper" && activeStep + 1 === 1 ? (
            <></>
          ) : (
            template.reportType === "flexmonster" &&
            activeStep + 1 === 3 &&
            (Object.keys(QueryFilter).length > 0 &&
              Object.keys(MetaJson).length === 0 ? (
              <Box style={{ height: "calc(100% - 175px)", overflowY: "auto" }}>
                {Object.keys(QueryFilter)?.map((l) => (
                  <>
                    <Grid
                      container
                      justifyContent="center"
                      style={{ margin: "8px 0px" }}
                    >
                      <Grid
                        item
                        xs={5}
                        className={classes.grid2}
                      >
                        <Typography
                          variant="body2"
                          color="GrayText"
                          className={classes.typo}
                        >
                          {`${l} Type`}
                        </Typography>
                        <div
                          className={classes.div7}
                        >
                          <FormControl
                            style={{ width: 250 }}
                            size="small"
                            margin="dense"
                          >
                            <Select
                              labelId="demo-select-small"
                              id="demo-select-small"
                              value={sampleMetaJson[`${l}`]}
                              onChange={(e) =>
                                handleSchema(`${l}`, e.target.value)
                              }
                              fullWidth
                              input={<BootstrapInput />}
                              MenuProps={menuProps}
                              displayEmpty
                            >
                              <MenuItem disabled value="">
                                Select Type
                              </MenuItem>

                              {list?.map((v, index) => {
                                return (
                                  <MenuItem key={index} value={v.value}>
                                    {v.type}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Switch
                              checked={isRequiredJson[`${l}`]}
                              onChange={(e) =>
                                handleIsRequired(`${l}`, e.target.checked)
                              }
                              name="checkedB"
                              color="primary"
                            />
                            <Typography
                              variant="body2"
                              color="GrayText"
                              style={{ textTransform: "capitalize" }}
                            >
                              {"Is Required"}
                            </Typography>
                          </div>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => FromApiClicked()}
                          >
                            From API
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </>
                ))}
              </Box>
            ) : (
              <div style={{ height: "calc(100% - 175px)" }}>
                {Object.values(QueryFilter)?.length > 0 && (
                  <div style={{ padding: "0 8px", height: "45px" }}>
                    <Button
                      startIcon={<FilterListIcon />}
                      onClick={() => OnFilterClicked()}
                      variant="outlined"
                    >
                      Filter By
                    </Button>
                    <Button
                      onClick={() => onFlexResetClicked()}
                      variant="outlined"
                      style={{ marginLeft: "8px" }}
                    >
                      Reset
                    </Button>
                  </div>
                )}
                <div style={{ height: "calc(100% - 45px)" }}>
                  <FlexmonsterReact.Pivot
                    ref={pivot}
                    toolbar={true}
                    width="100%"
                    height="100%"
                    reportcomplete={onReportComplete}
                    beforetoolbarcreated={customizeToolbar}
                    licenseKey="Z7SQ-XDC84O-5H7144-0W4O65"
                  // customizeCell={() => customizeCellFunction()}
                  />
                </div>

                <Dialog
                  open={opendia}
                  onClose={() => closedia()}
                  value="lg"
                  autoFocus
                  fullWidth
                >
                  <DialogTitle>Create Column</DialogTitle>
                  <DialogContent>
                    <div style={{ margin: "10px 0" }}>
                      <DialogContentText>Display Name</DialogContentText>
                      <input
                        style={{ padding: "10px", width: "90%" }}
                        autoFocus
                        type="text"
                        value={column}
                        onChange={(e) => setColumn(e.target.value)}
                      />
                    </div>
                    {/* <div style={{ margin: "10px 0" }}>
                      <DialogContentText>Value Type</DialogContentText>
                      <select
                        style={{ padding: "10px", width: "94%" }}
                        onChange={(e) => setValueType(e.target.value)}
                        value={valueType}
                      >
                        <option>select</option>
                        <option value="general">General</option>
                        <option value="formula">Formula</option>
                      </select>
                    </div>
                    <div style={{ margin: "10px 0" }}>
                      <DialogContentText>Column Type</DialogContentText>
                      <select
                        style={{ padding: "10px", width: "94%" }}
                        onChange={(e) => {
                          setColumnType(e.target.value);
                        }}
                        value={columnType}
                      >
                        <option>select</option>
                        <option value="check-box">Check-Box</option>
                        <option value="button">Button</option>
                        <option value="link">Link</option>
                      </select>
                    </div> */}
                    <div style={{ margin: "10px 0" }}>
                      <DialogContentText>Column Value</DialogContentText>

                      <input
                        style={{ padding: "10px", width: "90%" }}
                        autoFocus
                        type="text"
                        value={columnvalue}
                        onChange={(e) => setColumnvalues(e.target.value)}
                      />
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => closedia()}>Cancel</Button>
                    <Button onClick={() => columnset()}>Add</Button>
                  </DialogActions>
                </Dialog>
              </div>
            ))
          )}
          {template.reportType === "jasper" && activeStep + 1 === 3 ? (
            <Grid container spacing={2} style={{ minHeight: "60vh" }}>
              <Grid item xl={6} xs={6} lg={6} md={6} sm={6}>
                <div>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: "bold", padding: "5px" }}
                  >
                    {"Get Input Params"}
                  </Typography>
                  <Box>
                    {console.log("listparams", listParams)}
                    {listParams.map(
                      (e) =>
                        e.includes("@") && (
                          <>
                            <Typography
                              style={{ fontWeight: "bold", margin: "10px 0" }}
                            >
                              {e}
                            </Typography>
                            <TextField
                              className={classes.root}
                              name={e}
                              fullWidth={true}
                              onChange={changeHandle}
                            ></TextField>
                          </>
                        )
                    )}
                  </Box>
                  <Button onClick={() => GenerateReport()}>Apply</Button>

                  {/* <Button onClick={() => downloadPDF()}>
                  Download Jasper Report
                </Button> */}
                </div>
              </Grid>
              <Grid item xl={6} xs={6} lg={6} md={6} sm={6}>
                <div>
                  {pdfView && (
                    <div>
                      <div
                        style={{
                          width: "100%",
                        }}
                      >
                        <embed
                          src={pdf}
                          width="100%"
                          style={{ height: "80vh" }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
          ) : null}
          {template.reportType === "flexmonster" && activeStep + 1 === 4 && (
            <div style={{ height: "calc(100% - 175px)" }}>
              {Object.values(QueryFilter)?.length > 0 && (
                <>
                  <Button
                    startIcon={<FilterListIcon />}
                    onClick={() => OnFilterClicked()}
                    variant="outlined"
                    style={{ marginLeft: "12px" }}
                  >
                    Filter By
                  </Button>
                  <Button
                    onClick={() => onFlexResetClicked()}
                    variant="outlined"
                    style={{ marginLeft: "8px" }}
                  >
                    Reset
                  </Button>
                </>
              )}
              <div style={{ height: "calc(100% - 45px)" }}>
                <FlexmonsterReact.Pivot
                  ref={pivot}
                  toolbar={true}
                  width="100%"
                  height="100%"
                  reportcomplete={onReportComplete}
                  beforetoolbarcreated={customizeToolbar}
                  licenseKey="Z7SQ-XDC84O-5H7144-0W4O65"
                // customizeCell={() => customizeCellFunction()}
                />
              </div>
              <Dialog
                open={opendia}
                onClose={() => closedia()}
                value="lg"
                autoFocus
                fullWidth
              >
                <DialogTitle>Create Column</DialogTitle>
                <DialogContent>
                  <div style={{ margin: "10px 0" }}>
                    <DialogContentText>Display Name</DialogContentText>
                    <input
                      style={{ padding: "10px", width: "90%" }}
                      autoFocus
                      type="text"
                      value={column}
                      onChange={(e) => setColumn(e.target.value)}
                    />
                  </div>
                  {/* <div style={{ margin: "10px 0" }}>
                      <DialogContentText>Value Type</DialogContentText>
                      <select
                        style={{ padding: "10px", width: "94%" }}
                        onChange={(e) => setValueType(e.target.value)}
                        value={valueType}
                      >
                        <option>select</option>
                        <option value="general">General</option>
                        <option value="formula">Formula</option>
                      </select>
                    </div>
                    <div style={{ margin: "10px 0" }}>
                      <DialogContentText>Column Type</DialogContentText>
                      <select
                        style={{ padding: "10px", width: "94%" }}
                        onChange={(e) => {
                          setColumnType(e.target.value);
                        }}
                        value={columnType}
                      >
                        <option>select</option>
                        <option value="check-box">Check-Box</option>
                        <option value="button">Button</option>
                        <option value="link">Link</option>
                      </select>
                    </div> */}
                  <div style={{ margin: "10px 0" }}>
                    <DialogContentText>Column Value</DialogContentText>

                    <input
                      style={{ padding: "10px", width: "90%" }}
                      autoFocus
                      type="text"
                      value={columnvalue}
                      onChange={(e) => setColumnvalues(e.target.value)}
                    />
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => closedia()}>Cancel</Button>
                  <Button onClick={() => columnset()}>Add</Button>
                </DialogActions>
              </Dialog>
            </div>
          )}
          <Divider style={{ margin: "10px 0" }} variant="middle" />

          <Box
            className={classes.box}
          >
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={() => handleBack()}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            {activeStep === steps.length - 1 ? (
              <Button onClick={() => handleFinish()}>Finish</Button>
            ) : (
              <Button onClick={() => handleNext()}>Next</Button>
            )}
          </Box>
        </React.Fragment>

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={alertstatus}
            sx={{ width: "100%" }}
          >
            {alertmsg}
          </Alert>
        </Snackbar>
        <Dialog
          open={diaforhead}
          onClose={() => closedia()}
          value="md"
          fullWidth
          autoFocus
        >
          <DialogTitle>Header & Footer Creation</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter The Addition Content To Display In Header
            </DialogContentText>
            <input
              style={{ padding: "10px", width: "80%" }}
              autoFocus
              type="text"
              value={headername}
              onChange={(e) => setHeadername(e.target.value)}
            />

            <DialogContentText>
              Enter The Logo URL In BASE-64{" "}
            </DialogContentText>
            <input
              style={{ padding: "10px", width: "80%" }}
              autoFocus
              type="text"
              value={logourl}
              onChange={(e) => setLogourl(e.target.value)}
            />

            <DialogContentText>
              Would you like to show date in the footer ?
            </DialogContentText>
            <div
              className={classes.div8}
            >
              <div style={{ padding: "0 10px 0 0 " }}>
                <label>Yes</label>
                <input
                  style={{ padding: "10px" }}
                  autoFocus
                  type="radio"
                  value="true"
                  name="date"
                  onChange={(e) => setDateflex(e.target.value)}
                />
              </div>
              <div style={{ padding: "0 10px 0 0 " }}>
                <label>No</label>
                <input
                  style={{ padding: "10px" }}
                  autoFocus
                  type="radio"
                  value="false"
                  name="date"
                  onChange={(e) => setDateflex(e.target.value)}
                />
              </div>
            </div>

            <DialogContentText>
              Would you like to show Number of pages in the footer ?
            </DialogContentText>
            <div
              className={classes.div9}
            >
              <div style={{ padding: "0 10px 0 0 " }}>
                <label>Yes</label>
                <input
                  style={{ padding: "10px" }}
                  autoFocus
                  type="radio"
                  value="true"
                  name="page"
                  onChange={(e) => setPageflex(e.target.value)}
                />
              </div>
              <div style={{ padding: "0 10px 0 0 " }}>
                <label>No</label>
                <input
                  style={{ padding: "10px" }}
                  autoFocus
                  type="radio"
                  value="false"
                  name="page"
                  onChange={(e) => setPageflex(e.target.value)}
                />
              </div>
            </div>
            <DialogContentText>
              Enter The Addition Content To Display In Footer
            </DialogContentText>
            <input
              style={{ padding: "10px", width: "80%" }}
              autoFocus
              type="text"
              value={footercont}
              onChange={(e) => setFootercont(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => closediapdf()}>Cancel</Button>
            <Button onClick={() => diasetpdf()}>Add</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openFilter}
          onClose={() => closeFilter()}
          value="sm"
          fullWidth
          autoFocus
        >
          <DialogTitle>
            <Typography variant="body1">Filter By</Typography>
          </DialogTitle>
          <DialogContent dividers>
            <>
              <Grid
                container
                spacing={2}
                direction="row"
                alignItems={"flex-start"}
              >
                {Object.keys(QueryFilter)?.map((l) => (
                  <>
                    {sampleMetaJson[`${l}`] === "array" && (
                      <Grid item ls={3} xs={3}>
                        <Typography
                          variant="body2"
                          color="GrayText"
                          style={{ textTransform: "capitalize" }}
                        >{`${l} Type`}</Typography>
                        <FormControl size="small" fullWidth margin="dense">
                          <Select
                            labelId="demo-select-small"
                            id="demo-select-small"
                            value={arraysType[`${l}`]}
                            onChange={(e) =>
                              OnArrayHandle(`${l}`, e.target.value)
                            }
                            fullWidth
                            input={<BootstrapInput />}
                            MenuProps={menuProps}
                            displayEmpty
                          >
                            <MenuItem disabled value="">
                              Select
                            </MenuItem>

                            {arrayType?.map((v, index) => {
                              return (
                                <MenuItem key={index} value={v.value}>
                                  {v.type}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}
                    <Grid item xs={sampleMetaJson[`${l}`] === "array" ? 8 : 12}>
                      <Typography
                        variant="body2"
                        color="GrayText"
                        style={{ textTransform: "capitalize" }}
                      >
                        {`${l}`} {isRequiredJson[`${l}`] && <IsRequired />}
                      </Typography>

                      {sampleMetaJson[`${l}`] === "number" ? (
                        <TextField
                          onChange={(e) =>
                            onHandleChange(`${l}`, e.target.value)
                          }
                          value={QueryObj[`${l}`] ?? ""}
                          fullWidth
                          placeholder={`Enter ${l}`}
                          id="textfield"
                          variant="outlined"
                          size="small"
                          margin="dense"
                          type="number"
                        />
                      ) : sampleMetaJson[`${l}`] === "boolean" ? (
                        <RadioGroup
                          aria-label={`${l}`}
                          name={`${l}`}
                          row
                          value={QueryObj[`${l}`] ?? ""}
                          onChange={(e) =>
                            onHandleChange(`${l}`, e.target.value)
                          }
                        >
                          <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="True"
                          />
                          <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="False"
                          />
                        </RadioGroup>
                      ) : sampleMetaJson[`${l}`] === "array" ? (
                        <CustionAddText
                          // label={data?.question}
                          label={"Add "}
                          options={
                            typeof QueryObj[`${l}`] === "string"
                              ? []
                              : QueryObj[`${l}`]
                          }
                          addTag={(value) => onHandleChange(`${l}`, value)}
                        // isReadonly={isReadyOnly}
                        // isrequired={isrequired}
                        />
                      ) : `${l}` === "activestatus" ? (
                        <RadioGroup
                          aria-label={`${l}`}
                          name={`${l}`}
                          row
                          value={QueryObj[`${l}`] ?? ""}
                          onChange={(e) =>
                            onHandleChange(`${l}`, e.target.value)
                          }
                        >
                          <FormControlLabel
                            value="true"
                            control={<Radio />}
                            label="True"
                          />
                          <FormControlLabel
                            value="false"
                            control={<Radio />}
                            label="False"
                          />
                        </RadioGroup>
                      ) : (
                        <TextField
                          onChange={(e) =>
                            onHandleChange(`${l}`, e.target.value)
                          }
                          value={QueryObj[`${l}`] ?? ""}
                          fullWidth
                          placeholder={`Enter ${l}`}
                          id="textfield"
                          variant="outlined"
                          size="small"
                          margin="dense"
                        />
                      )}
                    </Grid>
                  </>
                ))}

                {/* <Button
                disableRipple
                onClick={() => onFlexFilterClicked()}
                style={{ marginTop: "20px" }}
              >
                Filter
              </Button>
              <Button
                disableRipple
                onClick={() => onFlexResetClicked()}
                style={{ marginTop: "20px" }}
              >
                Reset
              </Button> */}
              </Grid>
            </>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => closeFilter()}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => onFlexFilterClicked()}
            >
              Filter
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openApi.open}
          onClose={() => closeApiModel()}
          value="sm"
          fullWidth
          autoFocus
        >
          <DialogTitle>
            <Typography variant="body1">API List</Typography>
          </DialogTitle>
          <DialogContent dividers>
            <>
              <Grid
                container
                spacing={2}
                direction="row"
                alignItems={"flex-start"}
              >
                <Grid item xs={12} sx={{ p: 2 }}>
                  <Typography variant="body1">
                    {" "}
                    URL <IsRequired />{" "}
                  </Typography>
                  <TextField
                    margin="dense"
                    value={mappingFilter?.url}
                    onChange={(e) => onHandleAPiChange("url", e.target.value)}
                    variant="outlined"
                    fullWidth
                    autoComplete="off"
                    size="small"
                  // error={error?.isinput}
                  // helperText={error?.isinput ? "This Field is Required*" : ""}
                  />
                </Grid>
                <Grid item xs={12} sx={{ p: 2 }}>
                  <Typography variant="body1">
                    Query <IsRequired />
                  </Typography>
                  <TextareaAutosize
                    aria-label="minimum height"
                    value={mappingFilter?.query}
                    onChange={(e) => onHandleAPiChange("query", e.target.value)}
                    placeholder="Type Here"
                    className={classes.autosize}
                    minRows={10}
                  // error={error?.isinputSchema}
                  // helperText={error?.isinputSchema ? "This Field is Required*" : ""}
                  />
                </Grid>
              </Grid>
            </>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => closeApiModel()}>
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => onTriggerClicked()}
            >
              Trigger
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </div >
  );
}
export default Stepform;

export const list = [
  { type: "String", value: "string" },
  { type: "Number", value: "number" },
  { type: "Boolean", value: "boolean" },
  { type: "Array", value: "array" },
];

export const arrayType = [
  { type: "String", value: "string" },
  { type: "Number", value: "number" },
];
