import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepg from "./homepage";
import Stepform from "./stepper";
import Ainqaflexmon from "./ainqaflex";
import Stepperedit from "./stepperedit";
import Viewpage from "./Viewpage";
import axios from "axios";
import ErrorIcon from "@mui/icons-material/Error";
import Typography from "@mui/material/Typography";
import { Card, Stack } from "@mui/material";
import { AlertProvider } from "./context/alert.context";
import { NavBar } from "./components/navbar";
import { ContextThemeProvider } from "./context/theme.context";
import { CssBaseline } from "@material-ui/core";
import { makeStyles } from '@mui/styles';
import config from "./config";


const useStyles = makeStyles({
  div: {
    width: "100%",
    height: "100%",
    position: "absolute",
    display: "flex",
    backgroundColor: "white",
  },
  card: {
    width: "50%",
    height: "50%",
    margin: "auto",
    display: "flex",
    boxShadow: "0 0 15px white",
    borderRadius: "20px",
    backgroundColor: "rgba(206, 17, 38, 0.05)",
  },
  erricon:{
    margin: "auto 0 auto 15px"
  },
  Typo: {
    margin: "auto 0 auto 30px",
    fontSize: "50px",
    fontFamily: "serif",
    fontStyle: "normal",
    fontWeight: "20%",
  },
  div1: {
    height: "100vh" 
  }
});

function Home() {
  const classes = useStyles();
  localStorage.setItem("reload", "");
  const [projectName, SetprojectName] = React.useState("");
  const [clientName, SetclientName] = React.useState("");
  const [pageerr, setPageerr] = React.useState(false);
  localStorage.setItem("url", window.location.search);
  let params = new URLSearchParams(window.location.search);
  console.log("awdadawd", localStorage.getItem("reload"));
  if (localStorage.getItem("reload") === "true") {
    console.log("true");
  }
  if (params.get("metadata_id")) {
    sessionStorage.setItem("metadataId", params.get("metadata_id"));
    sessionStorage.setItem("token", params.get("token"));
  }
  var metadataid = sessionStorage.getItem("metadataId");
  React.useEffect(() => {
    if (metadataid === null) {
      setPageerr(true);
    } else {
      setPageerr(false);
    }
    var datas = {
      db_name: config.DB_NAME,
      entity: "projectvstools",
      filter: `projectvstools.metadataid =='${metadataid}'`,
      return_fields: "projectvstools",
    };

    var confige = {
      method: "post",
      url: process.env.REACT_APP_API_URL,
      header: { "content-Type": "application/json" },
      data: datas,
    };

    axios(confige)
      .then((res) => {
        console.log("res");
        sessionStorage.setItem("clientid", res.data.result[0].clientid);
        sessionStorage.setItem("projectid", res.data.result[0].projectid);
        sessionStorage.setItem("databasename", res.data.result[0].dbname);
        var data1 = {
          db_name: config.DB_NAME,
          entity: "clients",
          filter: `clients._id =='${sessionStorage.getItem("clientid")}'`,
          return_fields: "clients",
        };
        var config1 = {
          method: "post",
          url: process.env.REACT_APP_API_URL,
          header: { "content-Type": "application/json" },
          data: data1,
        };
        axios(config1).then((res) => {
          console.log(res.data.result[0].clientname);
          SetclientName(res.data.result[0].clientname);
        });
        var data2 = {
          db_name: config.DB_NAME,
          entity: "projects",
          filter: `projects._id =='${sessionStorage.getItem("projectid")}'`,
          return_fields: "projects",
        };

        var config2 = {
          method: "post",
          url: process.env.REACT_APP_API_URL,
          header: { "content-Type": "application/json" },
          data: data2,
        };
        axios(config2).then((res) => {
          console.log(res.data.result[0].projectname);
          SetprojectName(res.data.result[0].projectname);
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }, [metadataid]);

  return (
    <>
      {pageerr ? (
        <div
          className={classes.div}
        >
          <Card
           className={classes.card}
          >
            <Stack direction="row" spacing={10} alignItems="center">
              <ErrorIcon
                variant=""
                fontSize="large"
                color="error"
                className={classes.erricon}
              />

              <Typography
                color="gray"
                className={classes.Typo}
              >
                500 something went wrong
              </Typography>
            </Stack>
          </Card>
        </div>
      ) : (
        <>
          <div className={classes.div1}>
            <AlertProvider>
              <ContextThemeProvider>
                <CssBaseline />
                <NavBar clientName={clientName} projectName={projectName} />
                <div style={{ height: "calc(100% - 64px)" }}>
                  <BrowserRouter>
                    <Routes>
                      <Route path="/" element={<Homepg />} />
                      <Route path="/newflex" element={<Ainqaflexmon />} />
                      <Route path="/steps" element={<Stepform />} />
                      <Route path="/view/:id" element={<Viewpage />} />
                      <Route path="/edit" element={<Stepperedit />} />
                    </Routes>
                  </BrowserRouter>
                </div>
              </ContextThemeProvider>
            </AlertProvider>
          </div>
        </>
      )}
    </>
  );
}

export default Home;
