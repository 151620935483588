import React from 'react';
import Table from './components/Table/Table'

function Homepg(props) {
  return (
    <div>
      <Table/> 
    </div>
  );
}

export default Homepg;