import React from "react";
import * as FlexmonsterReact from "react-flexmonster";
import "flexmonster/flexmonster.css";
// import { IsRequired } from "../Required";
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Box,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography,
  TextField,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Radio,
  MenuItem,
  InputBase,
  Select,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import CustionAddText from "../customAddTextBox";
import FilterListIcon from "@mui/icons-material/FilterList";
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";

import {
  getAllIdmPermission,
  getPrivatePermission,
  getReport,
  updatePermissionList,
  upsertPermissionList,
  upsertRepo,
  upsertReport,
} from "./calls";
import { DataSaverOnRounded } from "@mui/icons-material";

const useSytle = makeStyles((theme) => ({
  list: {
    paddingTop: 0,
    paddingBottom: 0,

    "& li": {
      // fontWeight: 200,
      paddingTop: 8,
      paddingBottom: 8,
      fontSize: "1rem",
      fontFamily: "TDS_Regular",
    },
    "& li:hover": {
      color: "white",
      background: "#2A60BC",
    },
    "& li.Mui-selected": {
      color: "black",
      background: "#e0f2ff",
    },
    "& li.Mui-selected:hover": {
      // background: "#6EC177",
      color: "white",
      background: "#2A60BC",
    },
  },
}));

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    /*  fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","), */
    /*   "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    }, */
  },
}))(InputBase);

const Flexview = (props) => {
  const { reportid, argument, userid, env } = props;
  const pivot = React.useRef(null);
  const [saveTemplate, setSaveAsTemp] = React.useState({ open: false });
  const [template, setTemplate] = React.useState({});
  const [reportData, setReportData] = React.useState();
  const [isMultipleEntities, setIsMultipleEntities] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [QueryFilter, setQueryFilter] = React.useState([]);
  const [QueryObj, setQueryobj] = React.useState({});
  const [isAnd, setIsAnd] = React.useState(false);
  const [openFilter, setCloseFilter] = React.useState(true);
  const [arraysType, setArraysType] = React.useState({});
  const classes = useSytle();

  const menuProps = {
    classes: {
      list: classes.list,
      paper: classes.paper,
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
    getContentAnchorEl: null,
  };

  const OnHandleChange = (key, value) => {
    setTemplate({ ...template, [key]: value });
  };

  React.useEffect(() => {
    console.log(env);
    if (props.view) {
      getReportData(reportid, env.readUrl);
    }
    //eslint-disable-next-line
  }, [reportid]);

  const getReportData = async (reportid, readUrl) => {
    // debugger;
    let data = await getReport(env.dbname, reportid, readUrl);
    setLoading(true);
    if (data.Code === 201) {
      let val = data.result[0];
      setReportData(val);
      let readQuery = JSON.parse(val.readquery);

      constructQuery(readQuery, argument, val);
      getFilterFormQuery(JSON.parse(val.readquery));

      setLoading(false);
    } else {
      alert("Something Went Wrong");
      setLoading(false);
    }
  };

  const constructQuery = async (readQ, data, val) => {
    // debugger;
    if (Object.keys(readQ).includes("filter")) {
      if (typeof readQ.filter === "object") {
        let IsMuitiple = readQ.entity.includes(",");
        if (IsMuitiple) {
          let getFiltervalues = Object.keys(readQ.filter).map((list) => {
            var strings = readQ.filter[list];
            var getad = strings.split("@").join(" ").split(" ");

            let newData = Object.keys(data).map((l) => {
              let value = l.replaceAll(`${list}_`, "");
              return { [value]: data[l] };
            });

            let newObj = Object.assign({}, ...newData);

            getad.map((e, i) =>
              Object.keys(newObj).map((l) => {
                // debugger;
                if (l === e) {
                  if (val.querySchema[`${list}_${l}`] === "string") {
                    return (getad[i] = "'" + newObj[e] + "'");
                  } else if (Array.isArray(newObj[e])) {
                    if (arraysType[`${l}`] === "string") {
                      let d = newObj[e].map((l) => "'" + l + "'");
                      return (getad[i] = "[" + d + "]");
                    } else {
                      return (getad[i] = "[" + newObj[e] + "]");
                    }
                  } else {
                    return (getad[i] = newObj[e]);
                  }
                }
                return getad;
              })
            );
            console.log(getad.join(" "));
            return { [list]: getad.join(" ") };
          });

          // console.log(getFiltervalues);
          let filterObj = Object.assign({}, ...getFiltervalues);
          let existingFilter = readQ.filter;
          Object.keys(existingFilter).forEach((key) =>
            Object.keys(filterObj).forEach((l) => {
              if (key === l) {
                return (existingFilter[key] = filterObj[key]);
              } else {
                return existingFilter[key];
              }
            })
          );

          //  Object.keys(readQ.filter)[0]
          // let entity = Object.keys(readQ.filter)[0];
          var updatedFilterQuery = {
            ...readQ,
            filter: existingFilter,
          };
          readFilterQuery(updatedFilterQuery, false);
        } else {
          var strings = readQ.filter[Object.keys(readQ.filter)[0]];
          var getad = strings.split("@").join(" ").split(" ");
          getad.map((e, i) =>
            Object.keys(data).map((v) => {
              if (v === e) {
                if (val.querySchema[`${v}`] === "string") {
                  return (getad[i] = "'" + data[e] + "'");
                } else if (Array.isArray(data[e])) {
                  if (arraysType[`${v}`] === "string") {
                    let d = data[e].map((l) => "'" + l + "'");
                    return (getad[i] = "[" + d + "]");
                  } else {
                    return (getad[i] = "[" + data[e] + "]");
                  }
                } else {
                  return (getad[i] = data[e]);
                }
              }
              return getad;
            })
          );

          console.log(getad.join(" "));
          //  Object.keys(readQ.filter)[0]
          let entity = Object.keys(readQ.filter)[0];
          var updatedFilterQuery1 = {
            ...readQ,
            filter: {
              ...readQ.filter,
              [entity]: getad.join(" "),
            },
          };
          readFilterQuery(updatedFilterQuery1, false);
        }
      } else if (typeof readQ.filter === "string") {
        var strings1 = readQ.filter;
        var getad1 = strings1.split("@").join(" ").split(" ");
        getad1.map((e, i) =>
          Object.keys(data).map((v) => {
            if (v === e) {
              if (val.querySchema[`${v}`] === "string") {
                return (getad1[i] = "'" + data[e] + "'");
              } else if (Array.isArray(data[e])) {
                if (arraysType[`${v}`] === "string") {
                  let d = data[e].map((l) => "'" + l + "'");
                  return (getad1[i] = "[" + d + "]");
                  // let d = JSON.stringify(data[e]);
                  // return (getad1[i] = d);
                  // return (getad1[i] = data[e]);
                } else {
                  return (getad1[i] = "[" + data[e] + "]");
                }
              } else {
                return (getad1[i] = data[e]);
              }
            }
            return getad1;
          })
        );

        console.log(getad1.join(" "));
        var updatedFilterQuery2 = { ...readQ, filter: getad1.join(" ") };
        readFilterQuery(updatedFilterQuery2, false);
      }
    }
  };

  const OnArrayHandle = (key, value) => {
    setArraysType({ ...arraysType, [key]: value });
  };

  const onready = () => {
    console.log("ready");
  };

  const getQueryObjectForArray = (string, entity, isMultipleEntity) => {
    debugger;
    if (isMultipleEntity) {
      let stringOfArr = string
        .replaceAll(" any in ", '":"')
        .replaceAll(" any == ", '":"')
        .replaceAll(" any== ", '":"')
        .replaceAll(" ANY ==", '":"')
        .replaceAll(" ANY== ", '":"')
        .replaceAll(" in ", "")
        .replaceAll(" ANY in", '":"')
        .replaceAll(" IN ", '":"')
        .replaceAll(" ", "")
        .replaceAll("@", "")
        .replaceAll("==", '":"')
        .replaceAll(`${entity}.`, "")
        .replaceAll("&&", '","');
      let value = '{"' + stringOfArr + '"}';
      //  setQueryFilter(JSON.parse(value));
      const arrayKeys = Object.keys(JSON.parse(value));
      let object = {};
      arrayKeys.forEach((key) => {
        object[`${entity}_${key}`] = "";
      });
      //  setQueryobj(object);
      return object;
    } else {
      let stringOfArr = string
        .replaceAll(" any in ", '":"')
        .replaceAll(" any == ", '":"')
        .replaceAll(" any== ", '":"')
        .replaceAll(" ANY ==", '":"')
        .replaceAll(" ANY== ", '":"')
        .replaceAll(" in ", "")
        .replaceAll(" ANY in", '":"')
        .replaceAll(" IN ", '":"')
        .replaceAll(" ", "")
        .replaceAll("@", "")
        .replaceAll("==", '":"')
        .replaceAll(`${entity}.`, "")
        .replaceAll("&&", '","');
      let value = '{"' + stringOfArr + '"}';
      setQueryFilter(JSON.parse(value));
      const arrayKeys = Object.keys(JSON.parse(value));
      let object = {};
      arrayKeys.forEach((key) => {
        object[key] = "";
      });
      setQueryobj(object);
      return object;
    }
  };

  const filterStringtoobj = (filter, entity) => {
    let getarray = filter
      .replaceAll("'", '"')
      .replaceAll(`${entity}.`, '"')
      .replaceAll("==", '":')
      .replaceAll("!=", '":')
      .replaceAll("&&", '",')
      .replaceAll("||", '",')
      .replaceAll("AND", '",')
      .replaceAll("OR", '",')
      .replaceAll("@", '"')
      .replaceAll(" ", "");
    let final = "{" + getarray + '"}';

    return final;
  };

  const getQueryObject = (string, entity, isMultipleEntity) => {
    debugger;
    if (isMultipleEntity) {
      setIsAnd(string.includes("&&"));
      // setIsOr(string.includes("||"));
      let final = filterStringtoobj(string, entity);
      // setQueryFilter(JSON.parse(final));
      const arrayKeys = Object.keys(JSON.parse(final));
      let object = {};
      arrayKeys.forEach((key) => {
        object[`${entity}_${key}`] = "";
      });
      // setQueryobj(object);
      return object;
    } else {
      setIsAnd(string.includes("&&"));
      // setIsOr(string.includes("||"));
      let final = filterStringtoobj(string, entity);
      setQueryFilter(JSON.parse(final));
      const arrayKeys = Object.keys(JSON.parse(final));
      let object = {};
      arrayKeys.forEach((key) => {
        object[key] = "";
      });
      setQueryobj(object);
      return object;
    }
  };

  const getFilterFormQuery = async (readquery) => {
    debugger;
    if (Object.keys(readquery).includes("filter")) {
      let isMultipleEntity = readquery?.entity?.includes(",");
      let filter = readquery.filter;
      //   setsampleMetaJson(metaData);
      if (isMultipleEntity) {
        if (typeof filter === "object") {
          let entities = readquery?.entity?.split(",");
          let getMultipleObj = entities.forEach((entity) => {
            let getFormObject = filter[`${entity}`];
            if (getFormObject.includes("@")) {
              if (
                getFormObject.includes("@") &&
                (getFormObject.includes("ANY IN") ||
                  getFormObject.includes("any in") ||
                  getFormObject.includes("any ==") ||
                  getFormObject.includes("ANY ==") ||
                  getFormObject.includes("ANY==") ||
                  getFormObject.includes(" in ") ||
                  getFormObject.includes(" IN ") ||
                  getFormObject.includes("any=="))
              ) {
                // let l = getFormObject.includes("@");
                let object = getQueryObjectForArray(
                  getFormObject,
                  entity,
                  isMultipleEntity
                );
                return object;
              } else {
                // let l = getFormObject.includes("@");
                let object = getQueryObject(
                  getFormObject,
                  entity,
                  isMultipleEntity
                );
                return object;
              }
            }
            // else {
            //   let obj = {};
            //   let l = false;
            //   return { metaData, obj, l, entity };
            // }
          });

          var object = Object.assign({}, ...getMultipleObj);
          setIsMultipleEntities(true);
          setQueryFilter(object);
          setQueryobj(object);
          let l = true;
          let meta = {};
          return { meta, object, l };
        }
      } else {
        if (typeof filter === "string") {
          // setMultipleEntities([]);
          setIsMultipleEntities(false);
          if (filter.includes("@")) {
            if (
              filter.includes("@") &&
              (filter.includes("ANY IN") ||
                filter.includes("any in") ||
                filter.includes("any ==") ||
                filter.includes("ANY ==") ||
                filter.includes("ANY==") ||
                filter.includes(" in ") ||
                filter.includes(" IN ") ||
                filter.includes("any=="))
            ) {
              let l = filter.includes("@");
              let object = getQueryObjectForArray(filter, readquery.entity);
              return { object, l };
            } else {
              let l = filter.includes("@");
              let object = getQueryObject(filter, readquery.entity);
              return { object, l };
            }
          } else {
            let obj = {};
            let l = false;
            return { obj, l };
          }
        } else if (typeof filter === "object") {
          // setMultipleEntities([]);
          setIsMultipleEntities(false);
          let getFormObject = filter[readquery.entity];
          if (getFormObject.includes("@")) {
            if (
              getFormObject.includes("@") &&
              (getFormObject.includes("ANY IN") ||
                getFormObject.includes("any in")) &&
              (getFormObject.includes("in") || getFormObject.includes("IN"))
            ) {
              let l = getFormObject.includes("@");
              let object = getQueryObjectForArray(
                getFormObject,
                readquery.entity
              );
              return { object, l };
            } else {
              let l = getFormObject.includes("@");
              let object = getQueryObject(getFormObject, readquery.entity);

              return { object, l };
            }
          } else {
            let obj = {};
            let l = false;

            return { obj, l };
          }
        }
      }
    } else {
      let obj = {};
      let l = false;
      setIsMultipleEntities(false);
      return { obj, l };
    }
  };

  const onHandleChange = (key, value) => {
    setQueryobj({ ...QueryObj, [key]: value });
  };

  const closeFilter = () => {
    setCloseFilter(false);
  };

  const OnFilterClicked = () => {
    setCloseFilter(true);
  };

  const onReportComplete = () => {
    console.log(">>>>", pivot.current.flexmonster.getReport());
    /*getFilterFormQuery(); */
    /*  pivot.current.flexmonster.on("reportcomplete", () => {
      if (pivot.current.flexmonster.getAllHierarchies().length === 0)
        pivot.current.flexmonster.alert({
          ...pivot.current.flexmonster,
          title: "The data set is empty",
          type: "error",
        });
    }); */
  };

  const readFilterQuery = (temp, isfilter) => {
    var config = {
      method: "post",
      url: `${env.readUrl}`,
      header: { "Content-Type": "application/json" },
      data: temp,
    };
    // console.log("config of read", config);
    axios(config)
      .then((res) => {
        // console.log(" for data reading", res.data.result);

        var report = {
          dataSource: {
            data: res.data.result,
          },
          options: {
            grid: {
              type: "flat",
              showGrandTotals: "off",
              showTotals: "off",
            },
          },
        };
        if (isfilter) {
          closeFilter();
        }
        // if (pivot.current != null) {
        try {
          pivot.current.flexmonster.setReport(
            JSON.parse(JSON.stringify(report))
          );
        } catch (error) {
          pivot.current.flexmonster.alert({
            title: "Data Type Error ",
            message: "Cannot set Unique Name ",
            buttons: [
              {
                label: "Reset",
                handler: function () {
                  // console.log("Button 2 handler");
                  onFlexResetClicked();
                },
              },
            ],
            type: "error",
            blocking: false,
          });
          pivot.current.flexmonster.clear();
          // onFlexResetClicked();
        }
        // getFilterFormQuery();
        // }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const onFlexResetClicked = () => {
    // debugger;
    let readQ = JSON.parse(reportData?.readquery);
    delete readQ.filter;
    readFilterQuery(readQ, true);
    // setQueryobj([]);
  };
  const IsValid = (obj) => {
    // debugger;
    if (isAnd) {
      let objKey = Object.keys(obj);
      if (objKey.length > 0) {
        let g = objKey.map((l) =>
          obj[`${l}`]?.trim()?.length === 0 ? false : true
        );
        // console.log(g.includes(false));
        return !g.includes(false);
      } else {
        return false;
      }
    } else {
      let objKey = Object.keys(obj);
      let g = objKey.map((l) => {
        if (Array.isArray(obj[`${l}`])) {
          return obj[`${l}`]?.length === 0 ? false : true;
        } else {
          return obj[`${l}`]?.trim()?.length === 0 ? false : true;
        }
      });
      return !g.includes(false);
    }
  };
  const onFlexFilterClicked = async () => {
    // debugger;
    let valid = IsValid(QueryObj);

    if (valid) {
      var data = QueryObj;

      var readQ = JSON.parse(reportData.readquery);

      if (Object.keys(readQ).includes("filter")) {
        if (typeof readQ.filter === "object") {
          if (isMultipleEntities) {
            let getFiltervalues = Object.keys(readQ.filter).map((list) => {
              var strings = readQ.filter[list];
              var getad = strings.split("@").join(" ").split(" ");

              let newData = Object.keys(data).map((l) => {
                let value = l.replaceAll(`${list}_`, "");
                return { [value]: data[l] };
              });

              let newObj = Object.assign({}, ...newData);

              getad.map((e, i) =>
                Object.keys(newObj).map((l) => {
                  if (l === e) {
                    if (reportData.querySchema[`${list}_${l}`] === "string") {
                      return (getad[i] = "'" + newObj[e] + "'");
                    } else if (Array.isArray(newObj[e])) {
                      if (arraysType[`${l}`] === "string") {
                        let d = newObj[e].map((l) => "'" + l + "'");
                        return (getad[i] = "[" + d + "]");
                      } else {
                        return (getad[i] = "[" + newObj[e] + "]");
                      }
                    } else {
                      return (getad[i] = newObj[e]);
                    }
                  }
                  return getad;
                })
              );
              console.log(getad.join(" "));
              return { [list]: getad.join(" ") };
            });

            // console.log(getFiltervalues);
            let filterObj = Object.assign({}, ...getFiltervalues);
            let existingFilter = readQ.filter;
            Object.keys(existingFilter).forEach((key) =>
              Object.keys(filterObj).forEach((l) => {
                if (key === l) {
                  return (existingFilter[key] = filterObj[key]);
                } else {
                  return existingFilter[key];
                }
              })
            );

            //  Object.keys(readQ.filter)[0]
            // let entity = Object.keys(readQ.filter)[0];
            var updatedFilterQuery = {
              ...readQ,
              filter: existingFilter,
            };
            readFilterQuery(updatedFilterQuery, true);
          } else {
            var strings = readQ.filter[Object.keys(readQ.filter)[0]];
            var getad = strings.split("@").join(" ").split(" ");
            getad.map((e, i) =>
              Object.keys(data).map((v) => {
                if (v === e) {
                  if (reportData.querySchema[`${v}`] === "string") {
                    return (getad[i] = "'" + data[e] + "'");
                  } else if (Array.isArray(data[e])) {
                    if (arraysType[`${v}`] === "string") {
                      let d = data[e].map((l) => "'" + l + "'");
                      return (getad[i] = "[" + d + "]");
                    } else {
                      return (getad[i] = "[" + data[e] + "]");
                    }
                  } else {
                    return (getad[i] = data[e]);
                  }
                }
                return getad;
              })
            );

            console.log(getad.join(" "));
            //  Object.keys(readQ.filter)[0]
            let entity = Object.keys(readQ.filter)[0];
            var updatedFilterQuery1 = {
              ...readQ,
              filter: {
                ...readQ.filter,
                [entity]: getad.join(" "),
              },
            };
            readFilterQuery(updatedFilterQuery1, true);
          }
        } else if (typeof readQ.filter === "string") {
          var strings1 = readQ.filter;
          var getad1 = strings1.split("@").join(" ").split(" ");
          getad1.map((e, i) =>
            Object.keys(data).map((v) => {
              if (v === e) {
                if (reportData.querySchema[`${v}`] === "string") {
                  return (getad1[i] = "'" + data[e] + "'");
                } else if (Array.isArray(data[e])) {
                  if (arraysType[`${v}`] === "string") {
                    let d = data[e].map((l) => "'" + l + "'");
                    return (getad1[i] = "[" + d + "]");
                    // let d = JSON.stringify(data[e]);
                    // return (getad1[i] = d);
                    // return (getad1[i] = data[e]);
                  } else {
                    return (getad1[i] = "[" + data[e] + "]");
                  }
                } else {
                  return (getad1[i] = data[e]);
                }
              }
              return getad1;
            })
          );

          console.log(getad1.join(" "));
          var updatedFilterQuery2 = { ...readQ, filter: getad1.join(" ") };
          readFilterQuery(updatedFilterQuery2, true);
        }
      }
    } else {
      alert("Required Fields Are Empty");
    }
  };

  function customizeToolbar(toolbar) {
    let tabs = toolbar.getTabs();
    toolbar.getTabs = function () {
      tabs = tabs.filter(
        (tab) =>
          tab.id !== "fm-tab-connect" &&
          tab.id !== "fm-tab-save" &&
          tab.id !== "fm-tab-open"
      );

      tabs.unshift({
        id: "fm-tab-newtab1",
        title: "Save",
        icon: "<img src='https://img.icons8.com/external-dreamstale-lineal-dreamstale/32/undefined/external-save-gaming-dreamstale-lineal-dreamstale.png' style='padding:8px;'  alt='Template'>",
        handler: templateModalOpen,
      });

      return tabs;
    };
  }

  const templateModalOpen = () => {
    setSaveAsTemp({ ...saveTemplate, open: true });
  };

  const closeTemplate = () => {
    setSaveAsTemp({ ...saveTemplate, open: false });
  };

  const onSaveUsTempClicked = async () => {
    // debugger;
    if (template.reportname.trim().length > 0) {
      let access = template.access;
      let reportname = template.reportname;
      let report = pivot.current.flexmonster.getReport();
      let data = { ...report, dataSource: { ...report.dataSource, data: [] } };
      let val = await upsertReport(
        env.upsertUrl,
        reportData,
        reportname,
        access,
        data,
        env.dbname
      );
      let clientid = reportData.clientid;
      let metadataid = reportData.metadataid;
      let projectid = reportData.projectid;
      let _id = val.Result[0]?.properties?.doc?._id;
      // let _id = val.Result[0]?.properties?.doc?._id;
      let repDetail = await upsertRepo(
        env.upsertUrl,
        env.projectdbname,
        reportname,
        "",
        _id,
        clientid,
        metadataid,
        projectid
      );

      /*   if (access === "Private") {
      let permlist = await getPrivatePermission(
        env.projectdbname,
        env.readQdmQuery,
        userid
      );
      if (permlist.length > 0) {
        permlist.map((l, index) =>{
          let value=l.permsn_repo.
          let value=l.permsn_repo.map(async (rolemapp, i) => {
            debugger;
            if (rolemapp.repoid === repDetail._id) {
              //update permission list
              let role = {
                ...rolemapp,
                permsndelete: true,
                permsnread: true,
                permsnupdate: true,
                permsnwrite: true,
              };
              let rolelist = l.permsn_repo.splice(i, 1, role);
              return rolelist

              let docs = {
                permsnid: l.permsnid,
                permsnname: l.permsnname,
                status: l.status,
                metadataid: l.metadataid,
                clientid: l.clientid,
                projectid: l.projectid,
                permsn_repo: rolelist,
              };
              return docs
              await updatePermissionList(
                env.projectdbname,
                env.idmUpsertUrl,
                docs,
                l._key
              );
            } else {
              upsert persmission list
              let obj = {
                repoid: repDetail._id,
                permsndelete: true,
                permsnread: true,
                permsnupdate: true,
                permsnwrite: true,
              };
              let rolelist = l.permsn_repo;
              rolelist.push(obj);
              return rolelist;
              return rolemapp;
              let docs = {
                permsnid: l.permsnid,
                permsnname: l.permsnname,
                status: l.status,
                metadataid: l.metadataid,
                clientid: l.clientid,
                projectid: l.projectid,
                permsn_repo: rolelist,
              };
              await upsertPermissionList(
                env.projectdbname,
                env.idmUpsertUrl,
                docs,
                l._key
              );
            }
          })}
        );
      } else {
        alert("No Permission Mapped");
      }
    } else {
      await getAllIdmPermission(projectdbname);
    } */

      closeTemplate();
    } else {
    }
  };

  /*   const Dataerr = () => {

  }; */

  return (
    <>
      {loading ? (
        <>
          <Grid container justifyContent="space-between">
            <Grid item lg={4}>
              <Box style={{ display: "flex" }}>
                <Skeleton sx={{ margin: 1 }} variant="circular">
                  <Avatar />
                </Skeleton>
                <Skeleton sx={{ margin: 1 }} variant="circular">
                  <Avatar />
                </Skeleton>
                <Skeleton sx={{ margin: 1 }} variant="circular">
                  <Avatar />
                </Skeleton>
                <Skeleton sx={{ margin: 1 }} variant="circular">
                  <Avatar />
                </Skeleton>
              </Box>
            </Grid>
            <Grid item lg={4}>
              <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                <Skeleton sx={{ margin: 1 }} variant="circular">
                  <Avatar />
                </Skeleton>
                <Skeleton sx={{ margin: 1 }} variant="circular">
                  <Avatar />
                </Skeleton>
                <Skeleton sx={{ margin: 1 }} variant="circular">
                  <Avatar />
                </Skeleton>
                <Skeleton sx={{ margin: 1 }} variant="circular">
                  <Avatar />
                </Skeleton>
              </Box>
            </Grid>
          </Grid>
          <Skeleton variant="rectangular" width="100%">
            <div style={{ paddingTop: "35%" }} />
          </Skeleton>
        </>
      ) : (
        <>
          {Object.values(QueryObj)?.length > 0 && (
            <div
              style={{
                padding: "0 8px",
                height: "52px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Button
                startIcon={<FilterListIcon />}
                onClick={() => OnFilterClicked()}
                variant="outlined"
              >
                Filter By
              </Button>
              <Button
                onClick={() => onFlexResetClicked()}
                variant="outlined"
                style={{ marginLeft: "8px" }}
              >
                Reset
              </Button>
            </div>
          )}
          <div style={{ height: "calc(100% - 64px)" }}>
            <FlexmonsterReact.Pivot
              ref={pivot}
              toolbar={true}
              width="100%"
              height="100%"
              ready={() => onready()}
              // reportfileerror={() => Dataerr()}
              reportcomplete={() => onReportComplete()}
              beforetoolbarcreated={customizeToolbar}
            />
          </div>
          <Dialog
            open={openFilter}
            onClose={() => closeFilter()}
            value="sm"
            fullWidth
            autoFocus
          >
            <DialogTitle>
              <Typography variant="body1">Filter By</Typography>
            </DialogTitle>
            <DialogContent dividers>
              <>
                <Grid container spacing={2} direction="row" alignItems="center">
                  {Object.keys(QueryObj)?.map((l) => (
                    <>
                      {reportData.querySchema[`${l}`] === "array" && (
                        <Grid item ls={3} xs={3}>
                          <Typography
                            variant="body2"
                            color="GrayText"
                            style={{ textTransform: "capitalize" }}
                          >{`${l} Type`}</Typography>
                          <FormControl size="small" fullWidth margin="dense">
                            <Select
                              labelId="demo-select-small"
                              id="demo-select-small"
                              value={arraysType[`${l}`]}
                              onChange={(e) =>
                                OnArrayHandle(`${l}`, e.target.value)
                              }
                              fullWidth
                              input={<BootstrapInput />}
                              MenuProps={menuProps}
                            >
                              {arrayType?.map((v, index) => {
                                return (
                                  <MenuItem key={index} value={v.value}>
                                    {v.type}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                      )}
                      <Grid
                        item
                        xs={reportData.querySchema[`${l}`] === "array" ? 8 : 12}
                      >
                        <Typography
                          variant="body2"
                          color="GrayText"
                          style={{ textTransform: "capitalize" }}
                        >
                          {`${l}`}
                          {/* {isRequiredJson[`${l}`] && <IsRequired />} */}
                        </Typography>

                        {reportData.querySchema[`${l}`] === "number" ? (
                          <TextField
                            onChange={(e) =>
                              onHandleChange(`${l}`, e.target.value)
                            }
                            value={QueryObj[`${l}`] ?? ""}
                            fullWidth
                            placeholder={`Enter ${l}`}
                            id="textfield"
                            variant="outlined"
                            size="small"
                            margin="dense"
                            type="number"
                          />
                        ) : reportData.querySchema[`${l}`] === "boolean" ? (
                          <RadioGroup
                            aria-label={`${l}`}
                            name={`${l}`}
                            row
                            value={QueryObj[`${l}`] ?? ""}
                            onChange={(e) =>
                              onHandleChange(`${l}`, e.target.value)
                            }
                          >
                            <FormControlLabel
                              value="true"
                              control={<Radio color="primary" />}
                              label="True"
                            />
                            <FormControlLabel
                              value="false"
                              control={<Radio color="primary" />}
                              label="False"
                            />
                          </RadioGroup>
                        ) : reportData.querySchema[`${l}`] === "array" ? (
                          <CustionAddText
                            // label={data?.question}
                            label={"Add "}
                            options={
                              typeof QueryObj[`${l}`] === "string"
                                ? []
                                : QueryObj[`${l}`]
                            }
                            addTag={(value) => onHandleChange(`${l}`, value)}
                            // isReadonly={isReadyOnly}
                            // isrequired={isrequired}
                          />
                        ) : `${l}` === "activestatus" ? (
                          <RadioGroup
                            aria-label={`${l}`}
                            name={`${l}`}
                            row
                            value={QueryObj[`${l}`] ?? ""}
                            onChange={(e) =>
                              onHandleChange(`${l}`, e.target.value)
                            }
                          >
                            <FormControlLabel
                              value="true"
                              control={<Radio />}
                              label="True"
                            />
                            <FormControlLabel
                              value="false"
                              control={<Radio />}
                              label="False"
                            />
                          </RadioGroup>
                        ) : (
                          <TextField
                            onChange={(e) =>
                              onHandleChange(`${l}`, e.target.value)
                            }
                            value={QueryObj[`${l}`] ?? ""}
                            fullWidth
                            placeholder={`Enter ${l}`}
                            id="textfield"
                            variant="outlined"
                            size="small"
                            margin="dense"
                          />
                        )}
                      </Grid>
                    </>
                  ))}
                </Grid>
              </>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => closeFilter()}>Cancel</Button>
              <Button color="primary" onClick={() => onFlexFilterClicked()}>
                Filter
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={saveTemplate.open}
            onClose={() => closeTemplate()}
            value="sm"
            fullWidth
            autoFocus
          >
            <DialogTitle>
              <Typography variant="body1">Save as Template</Typography>
            </DialogTitle>
            <DialogContent dividers>
              <Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body2"
                    style={{ textTransform: "capitalize" }}
                  >
                    Report Name<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    onChange={(e) =>
                      OnHandleChange(`reportname`, e.target.value)
                    }
                    value={template.reportname}
                    fullWidth
                    placeholder={`Enter Report Name`}
                    id="textfield"
                    variant="outlined"
                    size="small"
                    margin="dense"
                  />
                </Grid>

                {/* <Grid item xs={12}>
                  <Typography
                    variant="body2"
                    style={{ textTransform: "capitalize" }}
                  >
                    Privacy Setting
                  </Typography>

                  <RadioGroup
                    aria-label={`val`}
                    name={`access`}
                    row
                    value={template.access}
                    onChange={(e) => OnHandleChange("access", e.target.value)}
                  >
                    {PrivacyType?.map((v, index) => {
                      return (
                        <FormControlLabel
                          value={v.value}
                          control={<Radio color="primary" />}
                          label={v.type}
                        />
                      );
                    })}
                  </RadioGroup>
                </Grid> */}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button variant="text" onClick={() => closeTemplate()}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => onSaveUsTempClicked()}
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
};

export default Flexview;
export const list = [
  { type: "String", value: "string" },
  { type: "Number", value: "number" },
  { type: "Boolean", value: "boolean" },
  { type: "Array", value: "array" },
];
export const arrayType = [
  { type: "String", value: "string" },
  { type: "Number", value: "number" },
];

export const PrivacyType = [
  { type: "Public", value: "Public" },
  { type: "Private", value: "Private" },
];
