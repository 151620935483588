import React from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Dialog, IconButton, Paper, Typography } from "@material-ui/core";
import VisibilitySharpIcon from "@mui/icons-material/VisibilitySharp";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Delete from '../../Dailogsandpopups/Delete'
import { Button } from "@material-ui/core";
import LoadingSpinner from "../../LoadingSpinner";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  div: {
    padding: "20px",
    height: "100%",
  },
  paper: {
    boxShadow: "3px 3px 10px 2px rgba(0, 0, 0, 0.25)",
    padding: "10px",
    height: "100%",
  },
  Typo: {
    fontWeight: "600",
  },
  div1: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: "44px",
    padding: "0px 8px"
  },
  Tcontainer: {
    borderRadius: "15px",
    border: "1.5px solid grey ",
    marginTop: "8px",
    height: "calc(100% - 54px)"
  },
  Tcell: {
    color: "white",
    backgroundColor: "#00205C",
    borderBottom: "0px",
    height: "33px",
  }
})

function Homepage() {
  const classes = useStyles();
  const history = useNavigate();
  const [dataload, setDataload] = React.useState([]);
  const [deleteid, setDeleteid] = React.useState("");
  const [deletedialog, setDeletedialog] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const reportPage = () => {
    history("/steps");
  };

  var update = localStorage.getItem("reload");
  React.useEffect(() => {
    
    setIsLoading(true);
    var temp1 = {
      db_name: process.env.REACT_APP_DB_NAME,
      entity: process.env.REACT_APP_ENTITY,
      filter: `${process.env.REACT_APP_ENTITY
        }.metadataid=='${sessionStorage.getItem("metadataId")}'`,
      ismime_read: "true",
      return_fields: process.env.REACT_APP_ENTITY,
    };
    var config1 = {
      method: "post",
      url: process.env.REACT_APP_API_URL,
      headers: { "Content-Type": "application/json" },
      data: temp1,
    };
    axios(config1)
      .then((res) => {
        console.log("homepage", res.data.result);
        setDataload(res.data.result);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
    localStorage.setItem("reload", "false");
    // }
  }, [update]);

  /////Delete dailog open function////
  const handledelete = (v) => {
    setDeletedialog(true);
    console.log("v._key", v._key);
    setDeleteid(v._key);
  };
  /////delete function///
  const deletefunc = () => {
    var temp = {
      db_name: process.env.REACT_APP_DB_NAME,
      entity: process.env.REACT_APP_ENTITY,
      filter: `${process.env.REACT_APP_ENTITY}._key=='${deleteid}'`,
    };
    var config = {
      method: "post",
      url: process.env.REACT_APP_API_URL_DELETE,
      headers: { "Content-Type": "application/json" },
      data: temp,
    };
    axios(config)
      .then((res) => {
        window.location.reload(true);
      })
      .catch((err) => {
        console.log(err.data);
      });
    setDeletedialog(false);
  };

  /////deletepopupclose function/////
  const handleClosefordeletepopup = () => {
    setDeletedialog(false);
  };

  ///download function

  return (
    <>
      <div
        className={classes.div}
      >
        {isLoading ? <LoadingSpinner /> : null}
        
        <Paper
          className={classes.paper}
        >
          <div
            className={classes.div1}
          >
            <Typography
              variant="body1"
              className={classes.Typo}
            >
              Report Configuration View
            </Typography>

            <Button
              onClick={() => {
                reportPage();
              }}
              color="primary"
              variant="contained"
            >
              Add+
            </Button>
          </div>
          <TableContainer
            className={classes.Tcontainer}
          >
            <Table stickyHeader={true} size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    className={classes.Tcell}
                  >
                    S.No
                  </TableCell>
                  <TableCell
                    className={classes.Tcell}
                  >
                    Report Name
                  </TableCell>
                  <TableCell
                    className={classes.Tcell}
                  >
                    Report Type
                  </TableCell>
                  <TableCell
                    className={classes.Tcell}
                  >
                    View
                  </TableCell>
                  <TableCell
                    className={classes.Tcell}
                  >
                    Edit
                  </TableCell>
                  <TableCell
                    className={classes.Tcell}
                  >
                    Delete
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataload?.map((v, index) => {
                  return (
                    <TableRow key={v.reportid}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{v.reportname}</TableCell>
                      <TableCell>{v.reporttype}</TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => history(`/view/${v.reportid}`)}
                        >
                          <VisibilitySharpIcon
                            fontSize="small"
                            color="disabled"
                          />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => history("/edit", { state: v })}
                        >
                          <EditIcon fontSize="small" color="info" />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => {
                            handledelete(v);
                          }}
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <DeleteIcon fontSize="small" color="error" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        <Dialog
          open={deletedialog}
          onClose={() => {
            setDeletedialog(false);
          }}
        >
          <Delete
            handleClosefordeletepopup={handleClosefordeletepopup}
            deletefunc={deletefunc}
          />
        </Dialog>
      </div>
    </>
  );
}

export default Homepage;
