let config = {};
config.REACT_APP_API_URL = process.env.REACT_APP_API_URL;
config.UPDATE = process.env.REACT_APP_API_URL_UPDAT;
config.DELETE = process.env.REACT_APP_API_URL_DELETE;
config.UPSERT = process.env.REACT_APP_API_URL_UPSERT;
config.READ = process.env.REACT_APP_API_URL_READ;
config.QDMQueryREAD = process.env.REACT_APP_API_URL_QDMQueryREAD;
config.FILEUPLOAD = process.env.REACT_APP_API_FILEUPLOAD;
config.FILEDOWNLOAD = process.env.REACT_APP_API_FILEDOWNLOAD;
config.REGISTERREPORT = process.env.REACT_APP_API_REGISTERREPORT;
config.GENERATEREPORT = process.env.REACT_APP_API_GENERATEREPORT;
config.APP_ID = process.env.REACT_APP_ID;
config.AUTH_URL = process.env.REACT_APP_API_AUTH_URL;
config.PARSEQUERY = process.env.REACT_APP_API_PARSEQUERY_URL;
config.IdmServicesUpsert = process.env.REACT_APP_API_IdmServicesUpsert;
config.GETMETASAMPLEJSON = process.env.REACT_APP_API_GETMETASAMPLEJSON;
config.DB_NAME = process.env.REACT_APP_DB_NAME;
config.METADATA_DB_NAME = process.env.REACT_APP_METADATA_DB_NAME;
config.ENTITY = process.env.REACT_APP_ENTITY;
config.PRIMARYCARE_DB = process.env.REACT_PRIMARYCARE_DB;
config.QDM_ADMIN_URL = process.env.REACT_APP_QDM_ADMIN_ENDPOINT;

export default config;
